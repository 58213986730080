
import { useNotificationService } from "onekijs";
import { useCallback } from "react";

export const useModalNotification = (topic: string) => {
  const notificationService = useNotificationService();
  const sendNotification = useCallback(
    (type, msg, ttl) => {
      notificationService.send({
        topic,
        ttl: ttl,
        payload: {
          message: msg,
          type,
        },
      })
    }, [notificationService, topic],
  );

  const successNotification = useCallback(
    (msg, ttl = 0) => {
      sendNotification('success', msg, ttl)
    },
    [sendNotification],
  )

  const errorNotification = useCallback(
    (msg, ttl = 0) => {
      sendNotification('error', msg, ttl)
    },
    [sendNotification],
  )
    
  return [successNotification, errorNotification];
}