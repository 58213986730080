import { secure, useForm, useLocalService, useSetting } from 'onekijs';
import React, { FC, useCallback } from 'react';
import DefaultError from '../../modules/core/components/DefaultError';
import FormSubmitEntry from '../../modules/core/components/form/FormSubmitEntry';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../modules/core/libs/rbac';
import ProjectGenerator from '../../modules/devtools/components/project-generator/ProjectGenerator';
import ProjectGeneratorService, {
  initialState,
} from '../../modules/devtools/components/project-generator/ProjectGeneratorService';
import ResultModal from '../../modules/devtools/components/project-generator/ResultModal';
import SubmitLabel from '../../modules/core/components/form/SubmitLabel';

const ProjectGeneratorPage: FC = () => {
  const [state, service] = useLocalService(ProjectGeneratorService, initialState);
  const settings = useSetting('projectGenerator');

  const onClose = useCallback(() => {
    service.reset();
  }, [service]);

  const { Form } = useForm(service.createProject, {
    initialValues: {
      category: 'microservice',
      apiType: 'chn',
      buildType: 'k8s',
      iac_env: ['local', 'dev', 'tst', 'acc', 'prd'],
      iac_expose_from_onpremise: true,
      iac_protocols: 'https',
      aws_account_id_local: settings.awsAccount.local,
      aws_account_id_dev: settings.awsAccount.dev,
      aws_account_id_tst: settings.awsAccount.tst,
      aws_account_id_acc: settings.awsAccount.acc,
      aws_account_id_prd: settings.awsAccount.prd,
      aws_account_sam_bucket_local: settings.awsSamBucket.local,
      aws_account_sam_bucket_dev: settings.awsSamBucket.dev,
      aws_account_sam_bucket_tst: settings.awsSamBucket.tst,
      aws_account_sam_bucket_acc: settings.awsSamBucket.acc,
      aws_account_sam_bucket_prd: settings.awsSamBucket.prd,
      teams: ['ethias-open-insurance'],
    },
  });

  // const showState = useBind((category: string) => category === 'usa', [getValue('category')]);

  return (
    <PageLayout title="Project" titleColored="Generator">
      <>
        <p>
          Any sensitive information stored in configuration files (application.yml) must be encrypted. This tool
          encrypts a string using the public key of the Kubernetes platform
        </p>

        <Form className="w-full pt-10">
          {/* {!hide && (
            <input {...field('java.xms')} className="border" />
          )}
          <button onClick={() => setHide(!hide)}>Hide</button> */}
          <ProjectGenerator />
          <ResultModal state={state} onClose={onClose} />
          <div className="mt-6" />
          <FormSubmitEntry label={SubmitLabel} />
        </Form>
      </>
    </PageLayout>
  );
};

export default secure(ProjectGeneratorPage, canReadWriteDevTools, { ErrorComponent: DefaultError });
