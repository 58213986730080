import { AnonymousObject, useLocation, useRouter } from 'onekijs';
import React, { FC, useCallback, useMemo } from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import Tabs from '../../core/components/tabs/Tabs';
import { ApiType } from '../typings/api';
import ApiList from './ApiList';

type ApiTabsOptions = {
  apiTypes: ApiType[];
};

let ApiTabs: FC<ApiTabsOptions> = ({ apiTypes }) => {
  const router = useRouter();
  const query = router.query;
  const location = useLocation();

  const apiTypesIndex = useMemo(() => {
    const result: AnonymousObject = {};
    if (!apiTypes) return result;
    apiTypes.forEach((apiType, index) => {
      result[apiType.type] = index;
    });
    return result;
  }, [apiTypes]);

  const onTabSelect = useCallback(
    (index: number) => {
      const apiType = apiTypes[index];
      router.push(`${location.pathname}?type=${apiType.type}`);
    },
    [router, location, apiTypes],
  );

  let tabIndex = 1;
  if (query && query.type) tabIndex = apiTypesIndex[query.type as string];

  return (
    <>
      {apiTypes && (
        <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
          <TabList className="flex flex-col sm:flex-row">
            {apiTypes.map((apiType) => (
              <Tab
                key={apiType.type}
                className="text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none cursor-pointer"
              >
                {apiType.name}
              </Tab>
            ))}
          </TabList>
          {apiTypes.map((apiType) => (
            <TabPanel key={apiType.type}>
              <ApiList type={apiType.type} className="mt-4" />
            </TabPanel>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default ApiTabs;
