import React, { FC, useState } from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { FaGripHorizontal, FaSpinner, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Modal from 'react-modal';
import ModalContent from '../../../core/components/ModalContent';
import { ProjectGeneratorState } from './typing';

Modal.setAppElement('#root');

export type ResultModalOptions = {
  onClose: React.MouseEventHandler;
  state: ProjectGeneratorState;
};

const ResultModal: FC<ResultModalOptions> = ({ onClose, state }) => {
  return (
    <Modal isOpen={state.showModal} onRequestClose={onClose} className="modal-wrapper" overlayClassName="modal-overlay">
      <ModalContent onClose={onClose} title="Project Generator Result" TitleIcon={FaGripHorizontal}>
        <div className="px-10 py-5 text-gray-600 text-center">
          {state.loading && (
            <>
              <div>
                <FaSpinner className="fa-spin m-auto text-5xl" />
                <p className="text-2xl mt-4">{state.step} ...</p>
                <p className="text-sm">This can take up to one minute</p>
              </div>
              <OutputLog outputLog={state.outputLog} />
            </>
          )}
          {!state.loading && state.result === 0 && (
            <div>
              <div className="bg-green-100 text-green-600 border-2 border-green-300 p-2 rounded">
                <FaThumbsUp className="m-auto text-5xl" />
                <p className="text-2xl mt-4 font-medium">Project created successfully !</p>
              </div>
              <div className="text-left mt-6">
                <p className="text-gray-900 text-1xl font-medium">Next step</p>
                {state.repositories.map(repo => (
                  <GitClone repo={repo} />
                ))}
                
              </div>
              <OutputLog outputLog={state.outputLog} />
            </div>
          )}
          {!state.loading && state.result !== 0 && (
            <div>
              <div className="bg-red-100 text-red-600 border-2 border-red-300 p-2 rounded">
                <FaThumbsDown className="m-auto text-5xl" />
                <p className="text-2xl mt-4 font-medium">Project creation failed !</p>
                <p className="mt-4 font-medium">{state.errorMessage}</p>
              </div>
              <OutputLog outputLog={state.outputLog} />
            </div>
          )}
        </div>
        <div className="px-5 py-4 flex justify-end"></div>
      </ModalContent>
    </Modal>
  );
};

const GitClone: FC<{ repo: string }> = ({ repo }) => {
  return (
    <div className="bg-gray-100 px-2 py-1 font-mono">
      git clone{' '}
      <a href={repo} className="underline text-blue-500" target="_blank" rel="noopener noreferrer">
        {repo}
      </a>
    </div>
  );
};

const OutputLog: FC<{ outputLog?: string[] }> = ({ outputLog }) => {
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const onShowLogs = () => {
    setShowLogs(!showLogs);
  };
  return (
    <>
      <p className="text-right text-xs mt-4 cursor-pointer">
        <span onClick={onShowLogs}>Show generator logs</span>
        {!showLogs && <BiCaretDown className="inline-block" style={{ marginTop: '-1px' }} />}
        {showLogs && <BiCaretUp className="inline-block" style={{ marginTop: '-1px' }} />}
      </p>
      {showLogs && (
        <div className="text-left overflow-y-auto" style={{ maxHeight: '300px' }}>
          {outputLog && outputLog.map((log) => <Log log={log} />)}
        </div>
      )}
    </>
  );
};

const Log: FC<{ log: string }> = ({ log }) => {
  if (log.startsWith('+')) {
    return <p className="font-mono font-bold text-blue-500">{`>${log.substring(1)}`}</p>;
  }
  return <p className="font-mono text-gray-500">{log}</p>;
};

export default ResultModal;
