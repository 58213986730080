import React, { FC } from 'react';

export type SiteLayoutProps = {
    title: string;
    titleColored?: string;
    margin?:string;
}
const PageLayout: FC<SiteLayoutProps> = ({ title, titleColored,margin='6', children }) => {
  return (
    <div className="flex bg-gray-200">
      <div className="flex text-left px-8 md:px-12 w-full flex-col" style={{ marginTop: margin+'rem' }}>
        <div className="px-5 py-5 bg-white w-full rounded-md shadow-md mb-10">
          <h3 className="font-semibold text-gray-800 text-3xl mb-5">
            {title} {titleColored && <span className="text-indigo-600">{titleColored}</span>}
          </h3>
          {children}
        </div>
        <div className="px-5 py-4 flex justify-end"></div>
      </div>
    </div>
  );
};

export default PageLayout;
