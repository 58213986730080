import { useForm, useSecureDelete, useSetting } from 'onekijs';
import React, { FC } from 'react';
import { FaGripHorizontal } from 'react-icons/fa';
import Modal from 'react-modal';
import FormInputEntry from '../../../core/components/form/FormInputEntry';
import FormSection from '../../../core/components/form/FormSection';
import FormSubmitEntry from '../../../core/components/form/FormSubmitEntry';
import ModalContent from '../../../core/components/ModalContent';

Modal.setAppElement('#root');

export type DeleteUserModalProps = {
  username: string;
  onClose: () => void;
  isOpen: boolean;
};

const DeleteUser: FC<DeleteUserModalProps> = ({ username, isOpen, onClose }) => {
  const baseUrl = useSetting('server.baseUrl');
  const [submit] = useSecureDelete(`${baseUrl}/users/${username}`, {
    onSuccess: () => {
      onClose();
    },
  });
  const { Form } = useForm(submit);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-wrapper" overlayClassName="modal-overlay">
      <ModalContent
        onClose={onClose}
        title="Open Insurance user management"
        TitleIcon={FaGripHorizontal}
      >
        <Form>
          <FormSection title="Confirm user deletion" prependSeparator={false}>
            <p>
              Please confirm the deletion of the user by entering <span className="font-bold">{username}</span> in the
              field below
            </p>
            <FormInputEntry
              name="username"
              required={true}
              regex={username}
              requiredMessage="Please enter the username"
              regexMessage="please enter the username"
            />
            <FormSubmitEntry label="Delete" />
          </FormSection>
          
        </Form>
      </ModalContent>
    </Modal>
  );
};

// export default secure(AdminPage);
export default DeleteUser;
