import { useNotificationService } from 'onekijs';
import React, { useCallback } from 'react';
import PageLayout from '../../modules/core/layouts/PageLayout';

export default () => {
  // inject the central notificationService
  const notificationService = useNotificationService();

  // Function to send a notification on the topic referenced by the attribute "type"
  const sendNotification = useCallback(
    (type, msg, ttl = 0) => {
      notificationService.send({
        topic: type,
        ttl: ttl,
        payload: {
          message: msg,
        },
      });
    },
    [notificationService],
  );

  return (
    <PageLayout title="Notifcation" titleColored="Tester">
      <div>
        <button onClick={() => sendNotification('error', 'This is an error message')}>
          Send an error notification
        </button>
        <br/><br/>
        <button onClick={() => sendNotification('success', 'This is a sucess message', 5000)}>
          Send a success notification
        </button>
      </div>
    </PageLayout>
  );
};
