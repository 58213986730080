import { useFormContext, useRule, useValue } from 'onekijs';
import React, { FC, useRef } from 'react';
import FormInputEntry from '../../../../core/components/form/FormInputEntry';
import FormMultiSelectEntry from '../../../../core/components/form/FormMultiSelectEntry';
import FormSection from '../../../../core/components/form/FormSection';
import FormSelectEntry from '../../../../core/components/form/FormSelectEntry';
import ThreeColumns from '../../../../core/components/grid/ThreeColumns';
import { noEmpty, pascalCase } from '../../../../core/libs/string';

const springDb = {
  data: [
    {
      label: "ODS",
      value: "ods"
    },
    {
      label: "DB2 Mainframe",
      value: "db2Mainframe"
    },
    {
      label: "Oracle",
      value: "oracle"
    },
    {
      label: "MySQL",
      value: "mysql"
    },
    {
      label: "PostgreSQL",
      value: "postgres"
    },
    {
      label: "Other",
      value: "other"
    },
  ],
  other: [
    {
      label: 'JDBC (+ data JDBC)',
      value: 'dataJdbc',
    },
    {
      label: 'MySQL',
      value: 'mysql',
    },
    {
      label: 'PostgresSQL',
      value: 'postgres',
    },
    {
      label: 'Redis',
      value: 'redis',
    },
    {
      label: 'Elasticsearch',
      value: 'elasticsearch',
    },
  ]
};

const awsServices = [
  {
    label: 'Cognito',
    value: 'cognito',
  },
  {
    label: 'Dynamo DB',
    value: 'dynamodb',
  },
  {
    label: 'Dynamo DB (Local)',
    value: 'dynamodbLocal',
  },
  {
    label: 'Lambda',
    value: 'lambda',
  },
  {
    label: 'S3',
    value: 's3',
  },
  {
    label: 'SageMaker',
    value: 'sageMaker',
  },
  {
    label: 'SES',
    value: 'ses',
  },
  {
    label: 'SQS',
    value: 'sqs',
  },
];

const otherDependencies = [
  {
    label: 'Camel',
    value: 'camel',
  },
];

const basePackage = (serviceName: string, groupId: string) => {
  return groupId + '.' + pascalCase(serviceName).replace(/-/g, '').toLowerCase();
};

const buildDbOptionsList = (setValue: any, ref: any, apiType: string) => {
  let dbOptions = springDb['other'];
  let dbOptionsList = null;

  if (apiType === 'data') {
    dbOptions = springDb['data'];
    ref.current.previousApiType !== 'data' && setValue('spring_springDb', 'ods'); // reset value if coming from chn/exp/cap api type

    dbOptionsList = <FormSelectEntry name="spring_springDb" label="Database" required={true}>
      {dbOptions.map((dbOption) => <option key={dbOption.value} value={dbOption.value}>{dbOption.label}</option>)}
    </FormSelectEntry>;

  } else {
    ref.current.previousApiType === 'data' && setValue('spring_springDb', []); // reset value if coming from data api type
    dbOptionsList = <FormMultiSelectEntry name="spring_springDb" options={dbOptions} label="Database" placeholder="No database" />;
  }

  ref.current.previousApiType = apiType;
  return dbOptionsList;
}

// const envs = ['local', 'dev', 'tst', 'acc', 'prd'];

// defaultValue={`be.ethias.${pascalCase(serviceName).replace(/-/g, '').toLowerCase()}`}
const JavaMicroservice: FC = () => {
  const { setValue } = useFormContext();
  const type = useValue('type');
  const category = useValue('category');
  const apiType = useValue('apiType');
  const ref = useRef({
    previousApiType: apiType, // keep previous selected apiType value between renderings
  });
  useRule(
    (serviceName: string, groupId: string) => {
      if (noEmpty(serviceName) && noEmpty(groupId)) {
        setValue('java_basePackage', basePackage(serviceName, groupId));
      }
    },
    [useValue('serviceName'), useValue('java_groupId')], // a list of fields on which the rule reacts
  );

  if (category !== 'microservice' || (type !== 'java' && type !== 'javaMultimodule' && type !== 'javaBatch')) return null;

  return (
    <FormSection
      title="Java Microservice"
      description="Define the configuration variables specific to a Java Microservice"
    >
      <ThreeColumns>
        <FormInputEntry
          label="Gradle group ID"
          name="java_groupId"
          required={true}
          requiredMessage="Please indicate a group id"
          defaultValue="be.ethias"
        />
        <FormInputEntry
          label="Gradle version"
          name="java_version"
          required={true}
          requiredMessage="Please indicate a version"
          defaultValue="1.0.0"
        />
        <FormInputEntry
          label="Base package"
          name="java_basePackage"
          required={true}
          requiredMessage="Please indicate a version"
        />
      </ThreeColumns>
      {apiType !== 'batch' && (<ThreeColumns>
        <FormInputEntry
          label="Minimum memory in MB (Xms)"
          name="java_xms"
          required={true}
          requiredMessage="Please indicate a minimum memory"
          defaultValue="128"
        />
        <FormInputEntry
          label="Maximum memory in MB (Xms)"
          name="java_xmx"
          required={true}
          requiredMessage="Please indicate a maximum memory"
          defaultValue="256"
        />
      </ThreeColumns>)}
      
      <ThreeColumns>
        {buildDbOptionsList(setValue, ref, apiType)}
        <FormMultiSelectEntry name="aws_deps" options={awsServices} label="AWS Service" placeholder="No AWS Service" />
        <FormMultiSelectEntry
          name="spring_other"
          options={otherDependencies}
          label="Other dependencies"
          placeholder="No other dependencies"
        />
      </ThreeColumns>
      {/* <FormLabel htmlFor="">Cognito issuer trusted by the application</FormLabel>
      <table className="border-collapse table-auto w-full bg-white table-striped relative">
        <thead>
          <tr className="text-left">
            {envs.map((env: string) => (
              <TableHeader title={env.toUpperCase()} key={env} bgColor="bg-white" />
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key="cognito_issuer">
            {envs.map((env: string) => (
              <td className="border-dashed border-t border-gray-200">
                <div className="mt-2 mx-4">
                  <FormInputEntry
                    name={`java_cognito_${env}`}
                    required={true}
                    requiredMessage="Please indicate a cognito issuer"
                  />
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table> */}
    </FormSection>
  );
};

export default JavaMicroservice;
