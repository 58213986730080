import { AnonymousObject } from 'onekijs';

export const downloadFile = (data: AnonymousObject, filename: string, mimeType: string, a: any): void => {
  const json = JSON.stringify(data, null, 2);
  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([json], { type: mimeType });
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // other browsers
    const file = new File([json], filename, { type: mimeType });
    const exportUrl = URL.createObjectURL(file);
    a.href = exportUrl;
    a.download = filename;
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(exportUrl);
    }, 0);
  }
};
