import React, { FC } from 'react'

export type TwoColumnsOptions = {
  className?: string;
}

const TwoColumns: FC<TwoColumnsOptions> = ({ className, children }) => {
  return (
    <div className={`grid grid-cols-2 gap-4 ${className ? className : ''}`}>{children}</div>
  )
}

export default TwoColumns;