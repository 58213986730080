import { useFormContext, useRule, useValue } from 'onekijs';
import React, { FC } from 'react';
import FormInputEntry from '../../../../core/components/form/FormInputEntry';
import FormMultiSelectEntry from '../../../../core/components/form/FormMultiSelectEntry';
import FormSection from '../../../../core/components/form/FormSection';
import ThreeColumns from '../../../../core/components/grid/ThreeColumns';
import { noEmpty } from '../../../../core/libs/string';


const batchTargets = [
  {
    label: 'Necs',
    value: 'necs',
  },
  {
    label: 'Aws',
    value: 'aws',
  },
];

const JavaBatch: FC = () => {
  const { setValue } = useFormContext();
  // const apiType = useValue('apiType');
  const type = useValue('type');
  const category = useValue('category');
  useRule(
    (serviceName: string) => {
      if (noEmpty(serviceName)) {
        setValue('batch_jobName', serviceName);
      }
    },
    [useValue('serviceName')], // a list of fields on which the rule reacts
  );

  if (category !== 'microservice' || type !== 'javaBatch') return null;

  return (
    <FormSection
      title="Java Batch"
      description="Define the configuration variables specific to a Java Batch"
    >
      <ThreeColumns>
        <FormInputEntry
          label="Job name"
          name="batch_jobName"
          help="Must contain only alphanumeric characters and '-'. Examples: party, policy, edocument"
          required={true}
          requiredMessage="Please indicate a job name"
          regex="^[a-z0-9-]{1,50}$"
          regexMessage={'Job name must contain only contains lowercase and dash characters (max 50 characters)'}
        />
        <FormMultiSelectEntry name="batch_targets" options={batchTargets} label="Launch job on" placeholder="No platform" defaultValue={["necs"]}/>
      </ThreeColumns> 
    </FormSection>
  );
};

export default JavaBatch;
