import { secure } from 'onekijs';
import React from 'react';
import UserList from '../../../modules/admin/components/user/UserList';
import DefaultError from '../../../modules/core/components/DefaultError';
import PageLayout from '../../../modules/core/layouts/PageLayout';
import { canReadWriteAdmin } from '../../../modules/core/libs/rbac';

const ListUsers = () => {
  // const baseUrl = useSetting('server.baseUrl');
  // const collection = useCollection(`${baseUrl}/users`, {
  //   initialLimit: 30,
  // });
  return (
    <PageLayout title="Open Insurance" titleColored="User management">
      <>
        <p>
          This page helps you manage the users created on Cognito.
          <br />A user is a <i>physical</i> person and a technical user represents an <i>application</i> used for
          machine to machine communication.
        </p>
        <UserList limit={15} />
      </>
    </PageLayout>
  );
};

const ListUsersPage = secure(ListUsers, canReadWriteAdmin, { ErrorComponent: DefaultError});
export default ListUsersPage;
