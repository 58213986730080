import { InputProps, Select, SelectProps, useValidation } from 'onekijs';
import React, { FC, FunctionComponent } from 'react';
import FormEntry from './FormEntry';
import FormLabel from './FormLabel';

const FormElement: FC<SelectProps> = (props) => {
  const validation = useValidation(props.name);
  return (
    <>
      <Select
        className={`${props.className} block w-full text-gray-700 border border-gray-400 rounded py-2 px-4 leading-tight focus:outline-none focus:border-4 focus:border-indigo-500 ${
          validation.isError() ? 'border-red-500' : ''
        }`}
        id={props.name}
        {...props}
      />
    </>
  );
};

export type FormSelectEntryOptions = {
  label?: FunctionComponent | string;
  errorMessage?: string;
  help?: string;
} & InputProps;
const FormSelectEntry: FC<FormSelectEntryOptions> = ({ label, ...options }) => {
  let Label: FC;
  if (label && typeof label === 'string')
    Label = () => <FormLabel htmlFor={options.name} required={options.required}>{label}</FormLabel>;
  else Label = label as FunctionComponent;
  return <FormEntry Label={Label} FormElement={FormElement} {...options}/>;
};

export default FormSelectEntry;
