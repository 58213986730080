import React, { FC } from 'react';
import { BiRefresh } from 'react-icons/bi';
import FormLabel from '../../../core/components/form/FormLabel';


export type GitRepoLabelOptions = {
  htmlFor: string;
  refresh: (forceRefresh: boolean) => void;
};
const GitRepoLabel: FC<GitRepoLabelOptions> = React.memo(({ htmlFor, refresh }) => {
  return (
    <FormLabel htmlFor={htmlFor}>
      <span>GIT Repository</span>
      <BiRefresh
        className="inline-block mr-2 ml-1 text-xl cursor-pointer"
        style={{ marginTop: '-5px' }}
        onClick={() => refresh(true)}
      />
    </FormLabel>
  );
});

export default GitRepoLabel;
