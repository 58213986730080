import { useRouter } from 'onekijs';
import React, { FC, useEffect } from 'react';

type DefaultErrorProps = {
  error: {
    code: number
  }
}

const DefaultError: FC<DefaultErrorProps> = ({ error }) => {
  const router = useRouter();

  useEffect(() => {
    if (error.code === 401) {
      router.push('/login');
    }
  }, [error.code, router]);

  if (error.code === 401) {
    return null;
  }  
  
  return (
    <div style={{margin: '100px'}}>You are not authorized to view this page</div>
  );
};

export default DefaultError;