import { Notification, useNotifications } from 'onekijs';
import React, { FC, useEffect } from 'react';
import { toast, ToastContainer, TypeOptions } from 'react-toastify';
import { CloseButton } from './CloseButton';

type NotificationCenterProps = {};

const asMessage = (notification: Notification): string => {
  const payload = notification.payload || {};
  const code = payload.code ? `${payload.code}: ` : "";
  const message = payload.message ? `${payload.message} ` : "";
  const result = `${code}${message}`;
  if (result.length === 0) return "Unexpected error";
  return result;
}

const displayToast = (type: TypeOptions, notification: Notification): void => {
  toast(asMessage(notification), {
    toastId: notification.id,
    autoClose: notification.ttl ? notification.ttl : false,
    onClick: notification.remove,
    type: type,
    closeButton: CloseButton(notification),
  })
}

const NotificationCenter: FC<NotificationCenterProps> = () => {
  const errors = useNotifications('error');
  const successes = useNotifications('success');
  const warnings = useNotifications('warning');

  useEffect(() => {
    errors.forEach(notif => displayToast("error", notif));
    warnings.forEach(notif => displayToast("warning", notif));
    successes.forEach(notif => displayToast("success", notif));
  }, [errors, successes, warnings])

  return (
    <ToastContainer
      position="bottom-right"
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default NotificationCenter;