import React, { FC } from 'react';
import FormSectionSeparator from './FormSectionSeparator';

export type FormSectionOptions = {
  title: string;
  description?: string;
  prependSeparator?: boolean;
  noBackground?: boolean;
}

const FormSection: FC<FormSectionOptions> = ({ children, title, description, prependSeparator=true, noBackground=false }) => {
  return (
    <>
    {prependSeparator && <FormSectionSeparator />}
    <div className="bg-gray-100 p-4">
      <div className="md:grid md:grid-cols-6 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-600">{description}</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-5">
          <div className={`${noBackground ? '':'shadow'}`}>
            <div className={`px-4 pt-4 pb-6 ${noBackground ? '' : 'bg-white'} space-y-3`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default FormSection;

// sm:rounded-md sm:overflow-hidden
