import { secure, useSecureGet, useSecurityContext, useSetting } from 'onekijs';
import React, { FC } from 'react';
import ApiList from '../../modules/apis/components/ApiList';
import ApiTabs from '../../modules/apis/components/ApiTabs';
import { ApiType } from '../../modules/apis/typings/api';
import DefaultError from '../../modules/core/components/DefaultError';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadApis, canReadDocs } from '../../modules/core/libs/rbac';


let ListApisPage: FC = () => {
  const baseUrl = useSetting('server.baseUrl');
  const [apiTypes] = useSecureGet<ApiType[]>(`${baseUrl}/open-apis/types`);
  const [securityContext] = useSecurityContext()

  if (!securityContext) return null;
  return (
    <PageLayout title="Open Insurance" titleColored="API">
      <>
        <p>
          You can search for the APIS in API Catalog using the API name or description.
        </p>
        <div className="mb-4">
          {canReadDocs(securityContext) && <ApiTabs apiTypes={apiTypes} />}
          {!canReadDocs(securityContext) && <ApiList type="virtualapi" className="mt-4" />}
        </div>
      </>
    </PageLayout>
  );
};
ListApisPage = secure(ListApisPage, canReadApis, { ErrorComponent: DefaultError });
export default ListApisPage;
