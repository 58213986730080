import React, { FC } from "react";

export type DocumentationProps = {
  id: string,
  token: string,
  hash?: string,
  baseUrl: string
}

const Documentation: FC<DocumentationProps> = ({id, token, hash, baseUrl}) => {
  let url = `${baseUrl}/${id}/index.html`;
  if (hash) {
    url += hash;
  }

  return <iframe className="documentation" title="documentation" src={url} />;
};

export default Documentation;
