import { useGlobalProp, useSecurityContext, useSetting } from "onekijs";
import { MutableRefObject, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickAway = (ref: MutableRefObject<any>, onClickAway: EventListener) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside: EventListener = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway(event);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickAway]);
}

export const usePermission = (permission: string, rw='r') => {
    const [securityContext] = useSecurityContext();
    const idpName = useGlobalProp('auth.idpName');
    const idp = useSetting(`idp.${idpName}`);

    if (!idp) return false;
    return idp.hasPermission(securityContext, permission, rw)
}