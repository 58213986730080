import { Item, ItemMeta, Link, useRouteMatch, useSecurityContext, useSetting, useGlobalProp, get } from 'onekijs';
import React, { FC } from 'react';
import { IconContext } from 'react-icons/lib';
import { SiSwagger } from 'react-icons/si';
import DropDown, { DropDownEntry } from '../../core/components/navbar/dropDown/DropDown';
import { Api } from '../../core/libs/api';
import { canReadDevTools, canReadDocs } from '../../core/libs/rbac';

type ApiListEntryOptions = {
  api: Item<Api, ItemMeta> | undefined;
};

const sonarCloudLink = (project: string): string => {
  if (project.startsWith('ethias-oi-')) project = project.substring('ethias-oi-'.length);
  const idx = project.lastIndexOf('-');

  return `https://sonarcloud.io/dashboard?id=nrbdigital_ethias-oi-${project.substring(0, idx)}`;
};

const githubLink = (project: string): string => {
  if (project.startsWith('ethias-oi-')) project = project.substring('ethias-oi-'.length);
  const idx = project.lastIndexOf('-');
  return `https://github.com/nrbdigital/ethias-oi-${project.substring(0, idx)}`;
};

const hasSonarCloud = (project: string): boolean => {
  return project.startsWith('openapi');
};

const hasDoc = (project: string): boolean => {
  return project.startsWith('openapi');
};

const hasGithub = (project: string): boolean => {
  return true;
};

const hasMore = (api: Api, securityContext: any): boolean => {
  if (!canReadDevTools(securityContext)) return false;
  if (hasGithub(api.id) || hasSonarCloud(api.id)) return true;
  return false;
};

const moreMenuEntries = (api: Api, securityContext: any, url: string, apiBaseUrl: string, token: any) => {
  const entries: DropDownEntry[] = [];
  if (canReadDocs(securityContext) && hasDoc(api.id)) {
    entries.push({
      label: 'Technical documentation',
      link: `${url}/${api.shortid}/doc`,
    });
  }

  if (canReadDevTools(securityContext) && hasGithub(api.id)) {
    entries.push(
      {
        label: 'Changelog',
        link: `${url}/${api.shortid}/changelog`,
      },      
      {
        label: 'GIT repository',
        link: `${githubLink(api.shortid)}`,
      },
    );
  }

  if (canReadDevTools(securityContext) && api.hastest) {
    entries.push({
      label: 'Test reports',
      link: `${url}/${api.shortid}/test-reports`,
    });
  }

  if (canReadDevTools(securityContext) && api.shortid.indexOf('-data-') > -1) {
    const apiUrl = apiBaseUrl + '/' + api.shortid;
    entries.push({
      label: 'Data explorer',
      link: `${apiUrl}/explorer/index.html?#theme=Cosmo&hkey0=Authorization&hval0=${token}&uri=${apiUrl}`,
      newTab: true,
    });
  }

  if (canReadDevTools(securityContext) && hasSonarCloud(api.id)) {
    entries.push({
      label: 'Sonar Cloud reports',
      link: `${sonarCloudLink(api.shortid)}`,
    });
  }

  return entries;
};

const ApiListEntry: FC<ApiListEntryOptions> = ({ api }) => {
  const [securityContext] = useSecurityContext();
  const { url } = useRouteMatch();
  const apiBaseUrl = useSetting('server.apiBaseUrl');
  const data = api?.data;
  const version = data?.version ? `[${data.version}]` : '';
  const auth = useGlobalProp('auth');
  
  if (!data) return null;

  const token = get(auth, 'token.access_token');
  
  return (
    <div className="p-4 md:w-1/3">
      <div className="flex rounded-lg h-full bg-gray-100 hover:bg-indigo-100 p-8 flex-col">
        <div className="flex items-center mb-3">
          <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
            <IconContext.Provider value={{ size: '22px' }}>
              <SiSwagger />
            </IconContext.Provider>
          </div>
          <h2 className="text-gray-900 text-lg title-font font-medium"><Link href={`${url}/${data.id}`}>{data.name}</Link><span className="ml-2 text-xs text-gray-600">{version}</span></h2>
          
        </div>
        <div className="flex-grow">
          <p className="leading-relaxed text-base">{data.description}</p>

          <Link href={`${url}/${data.id}`} className="mt-3 text-indigo-500 inline-flex items-center">
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-green-600 bg-green-100 rounded-full">
              Swagger
            </span>
          </Link>

          
          <Link href={`${url}/${data.id}/redoc`} className="mt-3 text-indigo-500 inline-flex items-center">
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-blue-600 bg-blue-100 rounded-full">
              Documentation
            </span>
          </Link>

          {hasMore(data, securityContext) && (
            <DropDown
              active={false}
              label="More"
              className="text-xs font-bold text-gray-600"
              entries={moreMenuEntries(data, securityContext, url, apiBaseUrl, token)}
              menuClassName="md:w-64 z-50"
              style={{ outline: '0px', backgroundColor: 'transparent' }}
            />
          )}
          
        </div>
        
      </div>
      
    </div>
  );
};

export default ApiListEntry;
