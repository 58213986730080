import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProjectCreatorPage from './projects/new';
import ListUsersPage from './users';
import DecryptorPage from './devtools/decryptor';

const AdminRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/projects/new`}>
        <ProjectCreatorPage />
      </Route>
      <Route path={`${match.path}/devtools/decryptor`}>
        <DecryptorPage />
      </Route>
      <Route path={`${match.path}/users`}>
        <ListUsersPage />
      </Route>
    </Switch>
  );
};

export default AdminRouter;
