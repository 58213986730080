import { Collection, isCollectionLoading, ItemMeta } from 'onekijs';
import React, { createContext, FC } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { MdExpandMore } from 'react-icons/md';

export type TableOptions<T, M> = {
  collection: Collection<T, M>;
  nbRows?: number;
  maxHeight?: string;
};

export type TableContextProps = {
  collection: Collection<any, ItemMeta>;
}

export const TableContext = createContext<TableContextProps>({ collection: {} as any});

const Table: FC<TableOptions<any, ItemMeta>> = ({ collection, maxHeight, nbRows, children }) => {
  const limit = nbRows || 15;
  return (
    <TableContext.Provider value={{collection}}>
      <div className="bg-white rounded-lg shadow relative w-full">
        <div style={{ maxHeight: maxHeight || '800px', overflowY: 'auto' }}>
          <table className="shadow min-w-full">{children}</table>
        </div>
      </div>
      {collection.status === 'loading' && (
        <div>
          <FaSpinner className="mt-6 fa-spin m-auto" />
        </div>
      )}
      {collection.data && collection.hasMore && (
        <div onClick={() => collection.load(limit, (collection.getOffset() || 0) + limit)} className="text-center">
          <div className="inline-block bg-gray-200 border border-gray-300 cursor-pointer py-2 px-8 mt-4 rounded hover:shadow">
            {!isCollectionLoading(collection) && (
              <>
                <span>
                  <b>more</b>
                </span>
                <MdExpandMore className="inline-block ml-1" style={{ marginTop: '-2px' }} />
              </>
            )}
            {isCollectionLoading(collection) && <FaSpinner className="fa-spin m-auto" />}
          </div>
        </div>
      )}
    </TableContext.Provider>
  );
};

export default Table;
