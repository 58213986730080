import { AnonymousObject } from 'onekijs';

export const permission = {
  ADMIN: "admin",
  DEV: "dev",
  DOC: "docs",
  API: 'apis'
}

export const acls: AnonymousObject = { // role: { permissions }
  admin: {
    [permission.ADMIN]: ['r', 'w'],
    [permission.DEV]: ['r', 'w'],
    [permission.DOC]: ['r', 'w'],
    [permission.API]: ['r', 'w']
  },
  developer: {
    [permission.DEV]: ['r', 'w'],
    [permission.DOC]: ['r', 'w'],
    [permission.API]: ['r', 'w']
  },
  analyst: {
    [permission.DOC]: ['r', 'w'],
    [permission.API]: ['r', 'w']
  },
  consumer: {
    [permission.API]: ['r', 'w']
  }
}

export const roleHasPermission = (roles: string[], permission: string, rw = 'r') => {
  if (!roles) return false;
  const found = roles.find(role => {
    if (!acls[role]) return false;
    if (!acls[role][permission]) return false;
    if (acls[role][permission].includes(rw)) return true;
    return false;
  })
  if (found) return true;
  return false;
}

export const canReadDocs = (securityContext: any): boolean => {
  return can(securityContext, permission.DOC, 'r')
}

export const canReadWriteDocs = (securityContext: any): boolean => {
  return can(securityContext, permission.DOC, 'w')
}

export const canReadApis = (securityContext: any): boolean => {
  return true;
}

export const canReadWriteApis = (securityContext: any): boolean => {
  return true;
}

export const canReadDevTools = (securityContext: any): boolean => {
  return can(securityContext, permission.DEV, 'r')
}

export const canReadWriteDevTools = (securityContext: any): boolean => {
  return can(securityContext, permission.DEV, 'w')
}

export const canReadAdmin= (securityContext: any): boolean => {
  return can(securityContext, permission.ADMIN, 'r')
}

export const canReadWriteAdmin = (securityContext: any): boolean => {
  return can(securityContext, permission.ADMIN, 'w')
}

export const isBetaTester = (securityContext: any): boolean => {
  if (!securityContext) return false;
  if (!securityContext['cognito:groups']) return false;
  const roles: string[] = securityContext['cognito:groups']
  const found = roles.find(role => role === 'betatester'); 
  if (found) return true;
  return false;
}

const can = (securityContext: any, permission: string, rw = 'r'): boolean => {
  if (!securityContext['cognito:groups']) return false;
  return roleHasPermission(securityContext['cognito:groups'], permission, rw);
}