import React, { FC } from 'react'

export type ThreeColumnsOptions = {
  className?: string;
}

const ThreeColumns: FC<ThreeColumnsOptions> = ({ className, children }) => {
  return (
    <div className={`grid grid-cols-3 gap-4 ${className ? className : ''}`}>{children}</div>
  )
}

export default ThreeColumns;