import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";

const SiteLayout:FC = ({ children }) => {
  const location = useLocation();
  return (
    <>
    <div className="bg-gray-200 e-container">
      <Navbar />
      <main>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.pathname}
            timeout={{ enter: 300, exit: 300 }}
            classNames={"fade"}
          >
            <section className="route-section">{children}</section>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <Footer />
    </div>
    
    </>
  );
};

export default SiteLayout;
