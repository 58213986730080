import { useSecureGet, useSetting } from 'onekijs';
import React, { FC } from 'react';
import { FaGripHorizontal } from 'react-icons/fa';
import Modal from 'react-modal';
import FormInputEntry from '../../../core/components/form/FormInputEntry';
import FormMultiSelectEntry, { ReactSelectGroupOptions } from '../../../core/components/form/FormMultiSelectEntry';
import FormSection from '../../../core/components/form/FormSection';
import FormSubmitEntry from '../../../core/components/form/FormSubmitEntry';
import ModalContent from '../../../core/components/ModalContent';

Modal.setAppElement('#root');

export type UserFormOptions = {
  onClose: () => void;
  mode: 'edit' | 'new';
};

const standardGroups = ['admin', 'analyst', 'betatester', 'consumer', 'developer'];

const mapper = (group: string) => ({ label: group, value: group });

const groupOptions = (groups?: string[]): ReactSelectGroupOptions[] => {
  if (!groups)
    return [
      {
        label: 'Common groups',
        options: standardGroups.map(mapper),
      },
    ];
  const commonGroup = groups.filter((group) => standardGroups.includes(group)).map(mapper);
  const customGroups = groups.filter((group) => !standardGroups.includes(group)).map(mapper);
  return [
    {
      label: 'Common groups',
      options: commonGroup,
    },
    {
      label: 'Custom groups',
      options: customGroups,
    },
  ];
};

const UserForm: FC<UserFormOptions> = ({ onClose, mode }) => {
  const baseUrl = useSetting('server.baseUrl');
  let [groups] = useSecureGet(`${baseUrl}/groups`);
  return (
    <ModalContent
      onClose={onClose}
      title="Open Insurance user management"
      TitleIcon={FaGripHorizontal}
      className="w-full rounded-lg shadow-lg bg-white my-3"
    >
      <div className="px-10 py-5 text-gray-600">
        <FormSection
          title="General informations"
          description="Define the attribute of the user"
          prependSeparator={false}
        >
          <FormInputEntry
            label="Username"
            name="username"
            placeholder="john.doe"
            required={true}
            requiredMessage="Please indicate a username"
            readOnly={mode === 'edit'}
          />

          <FormInputEntry
            label="Email"
            name="email"
            placeholder="john.doe@gmail.com"
            required={true}
            requiredMessage="Please indicate an email"
          />

          <FormInputEntry
            label="First name"
            name="firstname"
            placeholder="John"
            required={true}
            requiredMessage="Please indicate a first name"
          />

          <FormInputEntry
            label="Last name"
            name="lastname"
            placeholder="Doe"
            required={true}
            requiredMessage="Please indicate a last name"
          />

          <FormMultiSelectEntry
            name="groups"
            options={groupOptions(groups?.groups)}
            label="Groups"
            placeholder="No group"
          />

          <FormSubmitEntry label="Submit" />
        </FormSection>
      </div>
      <div className="px-5 py-4 flex justify-end"></div>
    </ModalContent>
  );
};

export default UserForm;
