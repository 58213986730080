import { Item, ItemMeta } from 'onekijs';
import React, { FC } from 'react';

export type TrOptions<T, M> = {
  index: number;
  key?: string;
  item: Item<T, M>;
  onClick?: (e: any) => void;
};

const Tr: FC<TrOptions<any, ItemMeta>> = ({ index, item, key, onClick, children }) => {
  const trClassName = `${
    index % 2 === 0 ? 'bg-gray-100' : ''
  } cursor-pointer hover:bg-gray-700 text-gray-700 hover:text-gray-200 whitespace-no-wrap text-sm`;
  if (item?.meta?.loadingStatus === 'loading') return null;
  if (onClick) {
    return (
      <tr key={key || `key-${index}`} className={trClassName} onClick={onClick}>
        {children}
      </tr>
    );
  }
  return (
    <tr key={key || `key-${index}`} className={trClassName}>
      {children}
    </tr>
  );
};

export default Tr;
