import React, { FC } from 'react';
import { MdEnhancedEncryption, MdNoEncryption } from 'react-icons/md';
import { BiGitBranch } from 'react-icons/bi';

export type ButtonProps ={
  label?: string;
};
const SubmitLabel: FC<ButtonProps> = ({label='Create'}) => {
  if(label.match("Encrypt"))
  {
    return (
    <>
        <MdEnhancedEncryption className="inline-block mr-2" style={{ marginTop: '-4px' }} />
        {label}
    </>
    )
  }
  else if(label.match("Decrypt"))
  {
    return (
      <>
        <MdNoEncryption className="inline-block mr-2" style={{ marginTop: '-4px' }} />
        {label}
      </>
      )
  }
  else{
    return (
      <>
        <BiGitBranch className="inline-block mr-2" style={{ marginTop: '-4px' }} />
        Create
      </>
      )
  }

};

export default SubmitLabel;
