import React, { FC, useCallback, useRef } from 'react';
import { RiFileCopyLine } from 'react-icons/ri';

export type EncryptedPasswordOptions = {
  encryptedPassword?: string;
  copied?: boolean;
  setCopied: (copied: boolean) => void;
  text?:string;
};
const EncryptedPassword: FC<EncryptedPasswordOptions> = React.memo(({ encryptedPassword, setCopied, copied,text }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const copyToClipboard = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      textAreaRef.current.selectionStart = textAreaRef.current?.selectionEnd;
      textAreaRef.current.blur();
      setCopied(true);
    }
  }, [setCopied]);  
  return (
    <div className="mt-10">
      <div>
        <span className="font-bold">{text}</span>{' '}
        <RiFileCopyLine
          className="inline-block cursor-pointer"
          style={{ marginTop: '-4px' }}
          onClick={copyToClipboard}
        />
        {copied && <span className="ml-2 font-bold text-green-500">Copied !</span>}
        <textarea
          ref={textAreaRef}
          value={encryptedPassword}
          readOnly
          className="no-resize appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-40"
        ></textarea>
      </div>
    </div>
  );
});

export default EncryptedPassword;
