import {
  Input,
  secure,
  Textarea,
  useForm,
  useNotifications,
  useNotificationService,
  useSecurePost,
  useSetting,
} from 'onekijs';
import React, { FC } from 'react';
import { FaSpinner } from 'react-icons/fa';
import DefaultError from '../../../modules/core/components/DefaultError';
import ModalNotification from '../../../modules/core/components/NotificationModal';
import { useModalNotification } from '../../../modules/core/libs/notification';
import { canReadWriteAdmin } from '../../../modules/core/libs/rbac';

export type ProjectCreatorPageOptions = {
  topic: string;
};

const ProjectCreator: FC<ProjectCreatorPageOptions> = ({ topic }) => {
  const baseUrl = useSetting('server.baseUrl');
  const notificationService = useNotificationService();
  const notifications = useNotifications(topic);

  const [sendSuccessNotification, sendErrorNotification] = useModalNotification(topic);

  const [submit, submitLoading] = useSecurePost(`${baseUrl}/a/repositories/java-iac`, {
    onSuccess: () => {
      notificationService.clearTopic(topic);
      sendSuccessNotification('Project created successfully !', 10000);
    },
    onError: (error) => {
      sendErrorNotification(error.message);
    },
  });
  // const [submitLoading, setSubmitLoading] = useState(false);
  // const submit = (data) => {
  //   setSubmitLoading(true);
  //   setTimeout(() => {
  //     setSubmitLoading(false);
  //   },2000)
  // }
  const { Form } = useForm(submit, {
    initialValues: {
      org: 'nrbdigital',
      repo: {
        priv: true,
        autoInit: true,
      },
      team: {
        slug: 'ethias-open-insurance',
        permission: 'push',
      },
    },
  });

  return (
    <div className="flex bg-gray-200">
      <div className="flex text-left px-8 md:px-12 w-full flex-col" style={{ marginTop: '6rem' }}>
        <div className="px-5 py-5 bg-white w-full rounded-md shadow-md mb-10">
          <h3 className="font-semibold text-gray-800 text-3xl mb-5">
            Open Insurance <span className="text-indigo-600">Projects</span>
          </h3>
          <p>
            A project consists of one or two Github repositories. These repositories must follow a standard governance.
            <br />
            This tool creates compliant projects with correct secrets, securities, ...
          </p>

          <Form className="w-full">
            {notifications && (
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  {notifications.map((notification) => (
                    <ModalNotification notification={notification} />
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="repo-name"
                >
                  Name
                </label>
                <Input
                  name="repo.name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="repo-name"
                  type="text"
                  placeholder="ethias-oi-<service_name>"
                />
                <p className="text-gray-600 text-xs italic">
                  The name of the repository. Must start with <b>ethias-oi-</b>
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="repo-description"
                >
                  Description
                </label>
                <Textarea
                  name="repo.description"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-y"
                  id="repo-description"
                ></Textarea>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-full">
                <button
                  className="float-right shadow bg-blue-500 hover:bg-blue-900 focus:shadow-outline focus:outline-none text-white text-center font-bold py-2 px-4 rounded"
                  type="submit"
                  style={{ width: '80px', height: '40px' }}
                  disabled={submitLoading}
                >
                  {!submitLoading && <span className="m-auto">Create</span>}
                  {submitLoading && <FaSpinner className="fa-spin m-auto" />}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="px-5 py-4 flex justify-end"></div>
    </div>
  );
};
/*<div className="md:w-1/2 sm:w-full rounded-lg shadow-lg bg-white my-3">
      <div className="flex justify-between border-b border-gray-100 px-5 py-4">
        <div>
          <FaGripHorizontal className="fas fa-exclamation-circle text-blue-500 inline-block" />
          <span className="font-bold text-gray-700 text-lg align-middle ml-2">
            Ethias Open Insurance project
          </span>
        </div>
        <div>
          <button onClick={onClose}>
            <FaTimesCircle className="text-red-500 hover:text-red-600 transition duration-150 inline-block" />
          </button>
        </div>
      </div>*/
// const Notification = ({ notification }) => {
//   const success = notification.payload.type === 'success';
//   return (
//     <p className={`text-${success ? 'green' : 'red'}-500 font-medium`}>
//       {!success && <TiWarning className="inline-block mr-2" />}
//       {notification.payload.message}
//     </p>
//   );
// };

// export default secure(AdminPage);
const ProjectCreatorPage = secure(ProjectCreator);
export default secure(ProjectCreatorPage, canReadWriteAdmin, { ErrorComponent: DefaultError});
