import { secure, useSecureGet, useSetting } from 'onekijs';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import DefaultError from '../../modules/core/components/DefaultError';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadDocs } from '../../modules/core/libs/rbac';


let ChangelogPage: FC = () => {
  const baseUrl = useSetting('server.baseUrl');
  const { id } = useParams<{ id: string }>();
  const [changelog] = useSecureGet<string>(`${baseUrl}/open-apis/${id}/changelog`);

  return (
    <PageLayout title={id} titleColored="Changelog">
      <>
        <p className="changelog">
          {(changelog === undefined) && <div>Loading ...</div>}
          {(changelog !== undefined) && <ReactMarkdown>{changelog}</ReactMarkdown>}
        </p>
      </>
    </PageLayout>
  );
};
ChangelogPage = secure(ChangelogPage, canReadDocs, { ErrorComponent: DefaultError });
export default ChangelogPage;
