import React, { FC } from 'react';
import { Link } from 'onekijs';

export type DropDownEntryOptions = {
  link: string;
  label: string;
  newTab?: boolean;
  onClick: (open: boolean) => void;
};

const DropDownEntry: FC<DropDownEntryOptions> = ({ link, label, newTab, onClick }) => {
  return (
    <Link 
      href={link}
      onClick={() => onClick(false)}
      target={newTab === true ? '_blank' : undefined}
      className="cursor-pointer block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
    >
      {label}
    </Link>
  );
};

export default DropDownEntry;
