import React from 'react';
import { Link } from 'onekijs';
import { useSecurityContext } from 'onekijs';
import Search from '../modules/core/components/Search';

const HomePage = () => {
  const [securityContext, loading] = useSecurityContext();
  return (
    <div style={{ height: '600px' }} className="flex">
      <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
        <div>
          <h2 className="text-3xl font-semibold text-gray-800 md:text-4xl">
            Ethias <span className="text-indigo-600">Open Insurance</span>
          </h2>
          <p className="mt-2 text-sm text-gray-600 md:text-base">
            Ethias API Portal contains the APIs that are registered in Ethias API Gateway and are available for use. You can browse <Link href="/apis" className="text-blue-500">APIs</Link> and their documentation, and test APIs.<br/>
            You can search for the APIS in API Catalog using the API name or description.
          </p>
          {!loading && !securityContext && (
            <div className="flex justify-center lg:justify-start mt-6">
              <Link
                href="/login"
                className="px-10 py-3 bg-gray-900 text-gray-200 text-xs font-semibold rounded hover:bg-gray-800"
              >
                Log in
              </Link>
              <Link
                href="/login/signup"
                className="mx-4 px-10 py-3 bg-gray-400 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400"
              >
                Sign up
              </Link>
            </div>
          )}
          {!loading && securityContext && (
            <div className="mt-6">
              <div className="relative" style={{ width: '80%' }}>
                <Search />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="hidden lg:block lg:w-1/2"
        style={{ clipPath: 'polygon(10% 0, 100% 0%, 100% 100%, 0 100%)' }}
      >
        <div
          className="h-full object-cover"
          style={{
            backgroundImage:
              'url(https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80)',
          }}
        >
          <div className="h-full bg-black opacity-25"></div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
