import React from 'react';
import { Redirect } from 'react-router-dom';
import { useRouter } from 'onekijs';

const SignupPage = React.memo(() => {
  const router = useRouter();
  localStorage.setItem('onekijs.signup', 'true');
  router.saveOrigin();
  return <Redirect to="/login" />
});

SignupPage.displayName = 'SignupPage';

export default SignupPage;
