import React, { FC } from 'react';

const MajorLabel: FC = () => {
  return (
    <label
      className="uppercase tracking-wide text-gray-700 text-xs font-bold ml-2"
      htmlFor="major"
      style={{ lineHeight: '1.4rem', verticalAlign: 'top' }}
    >
      Major version ?
    </label>
  );
};

export default MajorLabel;
