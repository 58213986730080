import React, { FC } from 'react';
import { MenuContext } from '../typings/api';
import { GoFileDirectory } from 'react-icons/go';
import {TestReportSubMenuEntry} from './TestReportMenuEntry';

export type TestReportSubMenuProps = {
  context: MenuContext;
  name: string;
  testReports: string[];
  type: string;
};

const TestReportSubMenu: FC<TestReportSubMenuProps> = ({ context, name, testReports, type}) => {
  return (
    <>
      <p className={`flex items-center ml-4 space-x-2 text-gray-700 p-1 rounded-md font-medium focus:bg-gray-200 focus:shadow-outline`}>
        <span className="text-gray-600">
          <GoFileDirectory className="h-4" />
        </span>
        <span>{name}</span>
      </p>
      <ul className="text-sm mt-1">
        {testReports &&
          testReports.map((testReport) => (
            <TestReportSubMenuEntry
              key={testReport}
              context={context}
              selectedTest={testReport}
              type={type}
              selected={context.selectedTest === testReport && context.type === type}
            />
          ))}
      </ul>
    </>
  );
};

export default TestReportSubMenu;
