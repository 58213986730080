import React, { FC, useCallback, useContext } from 'react';
import { TableContext, TableContextProps } from './Table';

export type ThFilterOptions = {
  id: string;
  value: string;
};

export type ThOptions = {
  field: string;
  filter?: 'input' | 'select';
  filterOptions?: ThFilterOptions[];
  onFilter?: (e: any) => void;
};

const Th: FC<ThOptions> = ({ field, filter, filterOptions, onFilter, children }) => {
  const { collection } = useContext<TableContextProps>(TableContext);
  const defaultFilter = useCallback(
    (e) => {
      if (onFilter) {
        onFilter(e);
      } else if (e.key === 'Enter') {
        if (e.target.value !== '') {
          collection.addFilter({
            id: field,
            field,
            operator: 'eq',
            value: e.target.value,
          })
        } else {
          collection.removeFilter(field);
        }
        
      }
    },
    [collection, field, onFilter],
  )

  return (
    <th className="px-6 py-3 border-b border-gray-200 bg-gray-300 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider sticky top-0 z-10">
      {children}
      {filter === 'input' && (
        <input className="appearance-none w-full block bg-gray-100 text-gray-700 border rounded py-1 px-1 mt-1 leading-2 focus:outline-none focus:bg-white" autoComplete="chrome-off" onKeyDown={defaultFilter}></input>
      )}
      {!filter && (
        <input className="appearance-none w-1 block bg-gray-100 text-gray-700 border rounded py-1 px-1 mt-1 leading-2 focus:outline-none focus:bg-white invisible"></input>
      )}
    </th>
  );
};

export default Th;
