import { useForm, useSecureGet, useSecurePut, useSetting } from 'onekijs';
import React, { FC } from 'react';
import { FaSpinner } from 'react-icons/fa';
import Modal from 'react-modal';
import { User } from '../../typing';
import UserForm from './UserForm';

Modal.setAppElement('#root');

export type EditUserModalProps = {
  onClose: () => void;
  isOpen: boolean;
  username: string;
};

const EditUser: FC<EditUserModalProps> = ({ isOpen, username, onClose }) => {
  const baseUrl = useSetting('server.baseUrl');
  const [user, userLoading] = useSecureGet<User>(`${baseUrl}/users/${username}`);

  // if (!user || userLoading) return null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-wrapper"
      overlayClassName="modal-overlay"
    >
      {userLoading && (
        <div style={{ height: '300px' }}>
          <FaSpinner className="fa-spin m-auto" />
        </div>
      )}
      {!userLoading && user && <EditUserForm onClose={onClose} user={user} />}
    </Modal>
  );
};

export type EditUserFormProps = {
  onClose: () => void;
  user: User;
};

const EditUserForm: FC<EditUserFormProps> = ({ onClose, user }) => {
  const baseUrl = useSetting('server.baseUrl');
  const [submit] = useSecurePut(`${baseUrl}/users/${user.username}`, {
    onSuccess: () => {
      onClose();
    },
  });

  const { Form } = useForm(submit, {
    initialValues: user,
  });

  return (
    <Form>
      <UserForm onClose={onClose} mode="edit"/>
    </Form>
  );
};

// export default secure(AdminPage);
export default EditUser;
