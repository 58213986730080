import React, { FC } from 'react';

export type LabelOptions = {
  htmlFor: string;
  required?: boolean;
  help?: string;
  style?: React.CSSProperties;
};

const FormLabel: FC<LabelOptions> = ({ htmlFor, required, help, style, children }) => {
  return (
    <label className="block" htmlFor={htmlFor} style={style}>
      <span className="uppercase tracking-wide text-gray-700 text-xs font-bold">{children}
      {required && <span style={{color: 'red'}}> *</span>}
      </span>
      {help && <span className="text-gray-600 text-xs italic ml-4">{help}</span>}
    </label>
  );
};

export default FormLabel;
