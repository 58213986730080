import { useSecureGet, useSetting } from 'onekijs';
import React, { FC, InputHTMLAttributes, useCallback, useState } from 'react';
import Autosuggest, { InputProps } from 'react-autosuggest';
import { useHistory } from 'react-router-dom';

type Suggestion = {
  id: string,
  name: string,
  description: string
}

const getSuggestionValue = (suggestion: Suggestion) => suggestion.name;


const shouldRenderSuggestions = (value: string) => {
  return value.trim().length > 1;
};

const renderInputComponent: FC<InputHTMLAttributes<HTMLInputElement>> = inputProps => (
  <div className="w-full">
    <div className="my-2 p-1 bg-white flex border border-gray-300 rounded">
      <div className="flex flex-auto flex-wrap"></div>
      <input
        placeholder="Search APIs"
        {...inputProps}
        className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        autoFocus={true}
      />
      <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
        <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-up w-4 h-4"
          >
            <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
            <circle cx="10" cy="10" r="7" />
            <line x1="21" y1="21" x2="15" y2="15" />
          </svg>
        </button>
      </div>
    </div>
  </div>
);

const Search = () => {
  const baseUrl = useSetting('server.baseUrl');
  const [query, setQuery] = useState(null);
  const [value, setValue] = useState('');
  const [apis] = useSecureGet(
    (!query || query === '') ? null : `${baseUrl}/open-apis${query ? `?q=${query}` : ''}` as any, {
      throttle: 250
    } as any
  );
  let history = useHistory();

  let suggestions = (!query || !apis) ? [] : apis;
  const onSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      setQuery(value);
    },
    [setQuery]
  );
  const onSuggestionsClearRequested = useCallback(() => {
    setQuery(null); 
  }, []);

  const onSuggestionSelected = useCallback(
    (event, { suggestion }) => {
      setQuery(null);
      setValue(''); 
      if (!event.isDefaultPrevented()) {
        history.push(`/apis/${suggestion.id}`);
      }
    },
    [history]
  );

  const onBadgeClick = useCallback(
    (e, type, id) => {
      history.push(`/${type}s/${id}`);
      e.preventDefault();
    },
    [history]
  );

  const renderSuggestion = useCallback((suggestion: Suggestion) => {
    const description = suggestion.description || 'No description';
    return (
      <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
        <div className="w-full items-center flex">
          <div className="mx-2 -mt-1 w-3/4">
            {suggestion.name}
            <div className="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500">
              {description}
            </div>
          </div>
          <div className="w-1/4 flex">
            <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300 ">
              <div className="text-xs font-normal leading-none max-w-full flex-initial" onClick={(e) => onBadgeClick(e, 'api', suggestion.id)}>
                api
              </div>
            </div>
            <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-green-700 bg-green-100 border border-green-300 ">
              <div className="text-xs font-normal leading-none max-w-full flex-initial" onClick={(e) => onBadgeClick(e, 'doc', suggestion.id)}>
                doc
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [onBadgeClick]);  

  const inputProps: InputProps<Suggestion> = {
    placeholder: 'Search APIs',
    value,
    onChange: (_event, { newValue }) => setValue(newValue),
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent as any}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
    />
  );
};

export default Search;
