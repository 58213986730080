import { secure, useForm, useSecurePost, useSetting } from 'onekijs';
import React, { FC, useState } from 'react';
import DefaultError from '../../modules/core/components/DefaultError';
import FormInputEntry from '../../modules/core/components/form/FormInputEntry';
import FormSelectEntry from '../../modules/core/components/form/FormSelectEntry';
import FormSubmitEntry from '../../modules/core/components/form/FormSubmitEntry';
import SubmitLabel from '../../modules/core/components/form/SubmitLabel';
import PageLayout from '../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../modules/core/libs/rbac';
import EncryptedPassword from '../../modules/devtools/components/encryptor/EncyptedPassword';

const EncryptorPage: FC = () => {
  const [encryptedPassword, setEncryptedPassword] = useState<string | undefined>(undefined);
  const [copied, setCopied] = useState(false);
  const baseUrl = useSetting('server.baseUrl');
  const [submit, submitLoading] = useSecurePost(`${baseUrl}/devtools/cyphertexts`, {
    onSuccess: (dataencrypt) => {
      setEncryptedPassword(dataencrypt.cyphertext);
      setCopied(false);
    },
  });

  const { Form: EncryptForm } = useForm(submit, {
    initialValues: {
      env: 'Local',
    },
  });

  return (
    <div>
      <PageLayout title="Password" titleColored="Encryptor" margin="6">
        <>
          <p>
            Any sensitive information stored in configuration files (application.yml) must be encrypted. This tool
            encrypts a string using the public key of the Kubernetes platform
          </p>

          <EncryptForm name="Encrypt" className="w-full pt-10">
            <FormInputEntry
              label="Plan text password"
              name="plaintext"
              placeholder="Password in clear"
              required={true}
              requiredMessage="Please indicate a branch name"
            />
            <FormSelectEntry name="env" label="Environment">
              <option value="Local">Local</option>
              <option value="Dev">Development</option>
              <option value="Tst">Test</option>
              <option value="Acc">Acceptance</option>
              <option value="Prd">Production</option>
            </FormSelectEntry>
            <FormSubmitEntry formName="formEncrypt" label={() => <SubmitLabel label="Encrypt" />} />
          </EncryptForm>
          {encryptedPassword && !submitLoading && (
            <EncryptedPassword
              copied={copied}
              setCopied={setCopied}
              encryptedPassword={encryptedPassword}
              text="Encrypted Password"
            />
          )}
        </>
      </PageLayout>
    </div>
  );
};

export default secure(EncryptorPage, canReadWriteDevTools, { ErrorComponent: DefaultError });
