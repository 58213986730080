import { useGlobalProp, useSetting, secure, useGlobalState } from "onekijs";
import React, { FC } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import Documentation from '../../modules/docs/components/Documentation';
import { canReadDocs } from '../../modules/core/libs/rbac';
import DefaultError from '../../modules/core/components/DefaultError';

let DocPage: FC = () => {

  const { id } = useParams<{id: string}>();
  const { hash } = useLocation();
  const baseUrl = useSetting("server.docsUrl");
  const token = useGlobalProp('auth.token.access_token');
  const [, setAtTop] = useGlobalState("atTop", true);

  useEffect(() => {
    setAtTop(false);
  }, [setAtTop]);

  return (
    <Documentation
      baseUrl={baseUrl}
      id={id}
      token={token}
      hash={hash}
    />
  )
}

DocPage = secure(DocPage, canReadDocs, { ErrorComponent: DefaultError});
export default DocPage;
