import React, { FC } from 'react';

const FormSectionSeparator: FC = () => {
  return (
    <div className="hidden sm:block bg-gray-100" aria-hidden="true">
      <div className="p-4">
        <div className="border-t border-gray-300"></div>
      </div>
    </div>
  );
};

export default FormSectionSeparator;
