import { useSecureGet, useSetting } from 'onekijs';
import React, { FC, useState } from 'react';
import FormSelectEntry from '../../../core/components/form/FormSelectEntry';
import GitRepoLabel from './GitRepoLabel';

type SelectGitRepositoryOptions = {
  name: string;
  required?: boolean;
  requiredMessage?: string;
};

const SelectGitRepository: FC<SelectGitRepositoryOptions> = React.memo(({ name, required, requiredMessage }) => {
  const baseUrl = useSetting('server.baseUrl');
  const [repoForceRefresh, setRepoForceRefresh] = useState<boolean | null>(false);
  const [repositories, repositoriesLoading] = useSecureGet<string[]>(
    `${baseUrl}/devtools/ethias-oi-repositories?forceRefresh=${repoForceRefresh}`,
  );

  return (
    <FormSelectEntry
      name={name}
      required={required || false}
      requiredMessage={requiredMessage || 'Please indicate a GIT repository'}
      label={() => <GitRepoLabel htmlFor={name} refresh={() => setRepoForceRefresh(true)} />}
    >
      <option value="">{repositoriesLoading ? 'loading repository list...' : 'Select a repository'}</option>
      {!repositoriesLoading && repositories && repositories.map((repo) => <option key={repo} value={repo}>{repo}</option>)}
    </FormSelectEntry>
  );
});

export default SelectGitRepository;
