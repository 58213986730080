import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import ApiPage from './api';
import DocPage from './doc';
import ListApisPage from '.';
import TestReportPage from './test-report';
import ChangelogPage from './changelog';
import RedocPage from './redoc';

const ApisRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id/doc`}>
        <DocPage />
      </Route>
      <Route path={`${match.path}/:id/test-reports`}>
        <TestReportPage />
      </Route>
      <Route path={`${match.path}/:id/changelog`}>
        <ChangelogPage />
      </Route>
      <Route path={`${match.path}/:type/:id/redoc`}>
        <RedocPage />
      </Route>      
      <Route path={`${match.path}/:type/:id`}>
        <ApiPage />
      </Route>
      <Route path={match.path}>
        <ListApisPage />
      </Route>
    </Switch>
  );
};

export default ApisRouter;
