import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SiteLayout from '../modules/core/layouts/SiteLayout';
import AdminRouter from './admin/_router';
import ApisRouter from './apis/_router';
import DevToolsRouter from './devtools/_router';
import HomePage from './home';
import LoginRouter from './login/_router';

const RootRouter = () => {
  return (
    <Switch>
      <Route path="/login">
        <LoginRouter />
      </Route>
      <Route>
        <SiteLayout>
          <Switch>
            <Route path="/apis">
              <ApisRouter />
            </Route>
            <Route path="/docs">
              <ApisRouter />
            </Route>
            <Route path="/devtools">
              <DevToolsRouter />
            </Route>
            <Route path="/admin">
              <AdminRouter />
            </Route>
            <Route>
              <HomePage />
            </Route>
          </Switch>
        </SiteLayout>
      </Route>
    </Switch>
  );
};

export default RootRouter;
