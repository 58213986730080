import { useFormContext, useRule, useValue } from 'onekijs';
import React, { FC } from 'react';
import FormInputEntry from '../../../../core/components/form/FormInputEntry';
import FormLabel from '../../../../core/components/form/FormLabel';
import FormMultiSelectEntry from '../../../../core/components/form/FormMultiSelectEntry';
import FormSection from '../../../../core/components/form/FormSection';
import ThreeColumns from '../../../../core/components/grid/ThreeColumns';
import TableHeader from '../../../../core/components/TableHeader';
import { noEmpty } from '../../../../core/libs/string';

const environments = [
  {
    label: 'Local',
    value: 'local',
  },
  {
    label: 'Development',
    value: 'dev',
  },
  {
    label: 'Test',
    value: 'tst',
  },
  {
    label: 'Acceptance',
    value: 'acc',
  },
  {
    label: 'Production',
    value: 'prd',
  },
];

const defaultHostname = (env: string) => {
  return env === 'prd' ? 'openapi.ethias.be' : `${env}.openapi.ethias.be`;
};

const defaultPrivateHostname = (env: string) => {
  return env === 'prd' ? 'openapi.aws.ethias.be' : `${env}.openapi.aws.ethias.be`;
};

const Kubernetes: FC = () => {
  const category = useValue('category');
  const buildType = useValue('buildType');
  const envs = useValue('iac_env');
  const apiType = useValue('apiType');
  const exposeFromOnpremise: boolean = useValue('iac_expose_from_onpremise');
  const { setValue } = useFormContext();
  useRule(
    (xms: string) => {
      if (noEmpty(xms)) {
        setValue('iac_minMemory', '' + Math.round(parseInt(xms) * 1.25));
      }
    },
    [useValue('java_xms')], // a list of fields on which the rule reacts
  );

  useRule(
    (xmx: string) => {
      if (noEmpty(xmx)) {
        setValue('iac_maxMemory', '' + Math.round(parseInt(xmx) * 1.25));
      }
    },
    [useValue('java_xmx')], // a list of fields on which the rule reacts
  );

  useRule(
    (gitPrefix: string, serviceName: string, apiType: string) => {
      if (noEmpty(gitPrefix) && noEmpty(serviceName) && noEmpty(apiType)) {
        setValue('iac_image', `252789945126.dkr.ecr.eu-west-1.amazonaws.com/${gitPrefix}${serviceName}-${apiType}`);
      }
    },
    [useValue('gitPrefix'), useValue('serviceName'), useValue('apiType')], // a list of fields on which the rule reacts
  );

  if ((buildType !== 'k8s' && buildType !== "scdf") || category !== 'microservice') return null;

  return (
    <>
      <FormSection title="Kubernetes" description="Configure the Kubernetes platform">
        <FormInputEntry
          label="Container image"
          name="iac_image"
          required={true}
          requiredMessage="Please indicate a container image"
          defaultValue="252789945126.dkr.ecr.eu-west-1.amazonaws.com/"
        />
        {apiType !== 'batch' && (<>
          <ThreeColumns>
            <FormInputEntry
              label="Minimum CPU"
              help="1 CPU = 1000"
              name="iac_minCpu"
              required={true}
              requiredMessage="Please indicate a minimum CPU"
              defaultValue="50"
            />
            <FormInputEntry
              label="Maximum CPU"
              help="1 CPU = 1000"
              name="iac_maxCpu"
              required={true}
              requiredMessage="Please indicate a maximum CPU"
              defaultValue="2000"
            />
          </ThreeColumns>
          <ThreeColumns>
            <FormInputEntry
              label="Minimum memory"
              help="Memory in MB"
              name="iac_minMemory"
              required={true}
              requiredMessage="Please indicate a minimum memory"
              defaultValue="256"
            />
            <FormInputEntry
              label="Maximum memory"
              help="Memory in MB"
              name="iac_maxMemory"
              required={true}
              requiredMessage="Please indicate a maximum memory"
              defaultValue="512"
            />
          </ThreeColumns>
        </>)}
        {buildType === 'k8s' && <FormMultiSelectEntry name="iac_env" options={environments} label="Environments" placeholder="No environment" />}
        {buildType === 'k8s' && envs && envs.length > 0 && (
          <>
            <FormLabel htmlFor="">Hostname to reach the application</FormLabel>
            <table className="border-collapse table-auto w-full bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  {envs.map((env: string) => (
                    <TableHeader title={env.toUpperCase()} key={env} bgColor="bg-white" />
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr key="hostname">
                  {envs.map((env: string) => (
                    <td className="border-dashed border-t border-gray-200">
                      <div className="mt-2 mx-4">
                        <FormInputEntry
                          name={`iac_hostname_${env}`}
                          required={true}
                          requiredMessage="Please indicate a hostname"
                          defaultValue={defaultHostname(env)}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        )}
      </FormSection>
      {buildType === 'k8s' && (
        <FormSection title="Internal API Gateway" description="Virtualize the application on the SAG API Gateway">
          <FormInputEntry
            type="checkbox"
            label="Virtualize the application on the internal API Gateway ?"
            name="iac_expose_from_onpremise"
          />
          {exposeFromOnpremise && envs && envs.length > 0 && (
            <>
              <FormLabel htmlFor="">Hostname to reach the application via the internal API Gateway</FormLabel>
              <table className="border-collapse table-auto w-full bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    {envs.map((env: string) => (
                      <TableHeader title={env.toUpperCase()} key={env} bgColor="bg-white" />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr key="hostname">
                    {envs.map((env: string) => (
                      <td className="border-dashed border-t border-gray-200">
                        <div className="mt-2 mx-4">
                          <FormInputEntry
                            name={`iac_hostname_internal_${env}`}
                            required={true}
                            requiredMessage="Please indicate a hostname"
                            defaultValue={defaultPrivateHostname(env)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </FormSection>
      )}
    </>
  );
};

export default Kubernetes;
