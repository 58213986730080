import React, { FC } from 'react';

type HeaderProps = {
  title: string;
  size?: string;
  bgColor?: string;
  fontColor?: string;
};

const TableHeader: FC<HeaderProps> = ({ title, size, bgColor, fontColor }) => {
  return (
    <th
      className={`${bgColor ? bgColor : 'bg-gray-200'} ${
        fontColor ? fontColor : 'text-gray-700'
      } sticky top-0 border-b border-gray-200 px-6 py-2 font-bold tracking-wider uppercase text-xs ${size}`}
    >
      {title}
    </th>
  );
};

export default TableHeader;
