import { Select, useFormContext, useRule, useValue } from 'onekijs';
import React, { FC } from 'react';
import { AiOutlineFunction } from 'react-icons/ai';
import { RiCodeSSlashFill } from 'react-icons/ri';
import { VscFileBinary } from 'react-icons/vsc';
import FormCard from '../../../../core/components/form/FormCard';
import FormSection from '../../../../core/components/form/FormSection';

const ProjectType: FC = () => {
  const { setValue } = useFormContext();
  const apiType = useValue('apiType');
  
  useRule(
    (category: string, apiTypeMs: string, apiTypeVs: string) => {
      if (category === 'microservice') {
        setValue('apiType', apiTypeMs);
      } else if (category === 'vs' || category === 'vs2') {
        setValue('apiType', apiTypeVs);
      }
    },
    [useValue('category'), useValue('apiTypeMs'), useValue('apiTypeVs')], // a list of fields on which the rule reacts
  ); 

  useRule(
    (apiTypeMs: string) => {
      if(apiTypeMs === 'batch') {
        setValue('type', 'javaBatch');
        setValue('buildType', 'scdf');
      }
    },
    [useValue('apiTypeMs')], // a list of fields on which the rule reacts
  );
  
  
  return (
    <FormSection title="Project type" description="Define the type of project to generate" noBackground={true} prependSeparator={false}>
      <div className="flex">
        <FormCard
          name="category"
          value="microservice"
          Icon={VscFileBinary}
          title="Microservice"
          Description={({ selected }) => (
            <>
              <p>A microservice exposes one or more REST API and is built in Java or Typescript</p>
              <p className="mt-2">The microservice can be executed on Kubernetes or in a Lambda</p>
              <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 mt-4">
                <dt className="text-sm font-medium text-gray-900">Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <Select
                    name="apiTypeMs"
                    className={`${selected ? 'bg-indigo-200' : 'bg-gray-200'} text-gray-900 p-1 w-full rounded`}
                    defaultValue="chn"
                  >
                    <option value="chn">Channel API</option>
                    <option value="exp">Experience API</option>
                    <option value="cap">Capability API</option>
                    <option value="data">Data Rest API</option>
                    <option value="batch">Batch Job</option>
                  </Select>
                </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Language</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <Select
                    name="type"
                    className={`${selected ? 'bg-indigo-200' : 'bg-gray-200'} text-gray-900 p-1 w-full rounded`}
                    defaultValue="java"
                    disabled={apiType === 'batch' ? true : false}
                  >
                    <option value="java">Java</option>
                    <option value="javaBatch">Java Batch</option>
                    <option value="javaMultimodule">Java Multimodule (BETA)</option>
                    <option value="node">TS (Nest Js)</option>
                  </Select>
                </dd>
              </div>              
              <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Platform</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <Select
                    name="buildType"
                    className={`${selected ? 'bg-indigo-200' : 'bg-gray-200'} text-gray-900 p-1 w-full rounded`}
                    defaultValue="k8s"
                    disabled={apiType === 'batch' ? true : false}
                  >
                    <option value="k8s">EKS (AWS Kubernetes)</option>
                    <option value="scdf">Spring cloud data flow</option>
                    <option value="lambda">AWS Lambda</option>
                    <option value="none">None (Bare metal)</option>
                  </Select>
                </dd>
              </div>
            </>
          )}
        />
        <FormCard
          name="category"
          value="vs"
          Icon={RiCodeSSlashFill}
          title="Virtual Service"
          Description={({ selected }) => (
            <>
              <p>A virtual service configures an API Gateway to expose correctly the APIs of a microservice.</p>
              <p className="mt-2">
                The virtual service can be applied on AWS API Gateway, SAG API Gateway or on Kubernetes
              </p>
              <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 mt-4">
                <dt className="text-sm font-medium text-gray-900">Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <Select
                    name="apiTypeVs"
                    className={`${selected ? 'bg-indigo-200' : 'bg-gray-200'} text-gray-900 p-1 w-full rounded`}
                    defaultValue="vs"
                  >
                    <option value="vs">AWS API GTW VS</option>
                    <option value="vs2">AWS API GTW VS (BETA!! common authorizer)</option>
                    <option value="ext">External API</option>
                    <option value="caf">CAF API</option>
                    <option value="esb">ESB API</option>
                    <option value="guidewire">Guidewire API</option>
                    <option value="zos">ZOS API</option>
                  </Select>
                </dd>
              </div>
            </>
          )}
        />

        <FormCard
          name="category"
          value="function"
          Icon={AiOutlineFunction}
          title="Function"
          Description={({ selected }) => (
            <>
              <p>A function reacts to a specific event and does one specific task</p>
              <p className="mt-2">The function is executed in a Lambda</p>
              <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 mt-4">
                <dt className="text-sm font-medium text-gray-900">Event</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                  <select className={`${selected ? 'bg-indigo-200' : 'bg-gray-200'} text-gray-900 p-1 w-full rounded`}>
                    <option value="authorizer">API GTW authorizer</option>
                    <option value="s3">S3</option>
                    <option value="sns">SNS</option>
                  </select>
                </dd>
              </div>
            </>
          )}
        />
      </div>
    </FormSection>
  );
};

export default ProjectType;
