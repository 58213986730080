import React, { FC } from 'react';
import { Link } from 'onekijs';

export type TestReportProps = {
  id: string;
  report: string;
  type?: string;
  selectedTest?: string;
  baseUrl: string;
};

const onLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
  const obj = e.target as HTMLIFrameElement;
  if (obj) {
    let height = 0;
    if (obj.contentWindow?.document.documentElement.scrollHeight) {
      height = obj.contentWindow?.document.documentElement.scrollHeight + 20;
    }
    obj.style.height = height + 'px';
  }
  
}

const buildTestUrl = (baseUrl: string, selectedTest?: string, pathBeforeIndex?: string) => {
  if (selectedTest != null) {
    if (!selectedTest.endsWith('.html')) {
      if (pathBeforeIndex != null) {
        selectedTest = selectedTest + '/' + pathBeforeIndex;
      }
      selectedTest = selectedTest + '/index.html';
    }

    return `${baseUrl}/${selectedTest}`;
  }
  return `${baseUrl}/${pathBeforeIndex == null ? '' : pathBeforeIndex + '/'}index.html`;
}

const TestReport: FC<TestReportProps> = ({ id, report, type, baseUrl, selectedTest }) => {
  if (type === '') {
    return (
      <div className="test-reports-empty">
        <p className="title">No test available !</p>
        <p className="back"><Link href="/apis" style={{color: 'blue'}}>Back to API page</Link></p>
      </div>
    );
  }
  let url = `${baseUrl}/${id}/${report}`;
  if (type === 'unit') {
    url = buildTestUrl(`${url}/tests/test`, selectedTest);
  } else if (type === 'e2e') {
    url = buildTestUrl(`${url}/e2e`, selectedTest);
  } else if (type === 'integration') {
    url = buildTestUrl(`${url}/tests/integrationTest`, selectedTest);
  } else {
    url = buildTestUrl(`${url}/jacoco`, selectedTest, 'test/html');
  }

  return (
    <iframe className="test-reports" title="documentation" src={url} onLoad={onLoad}/>
  );
};

export default TestReport;

// 
