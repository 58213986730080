import { useForm, useSecurePost, useSetting } from 'onekijs';
import React, { FC } from 'react';
import Modal from 'react-modal';
import UserForm from './UserForm';

Modal.setAppElement('#root');

export type CreateUserModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const CreateUser: FC<CreateUserModalProps> = ({ isOpen, onClose }) => {
  const baseUrl = useSetting('server.baseUrl');
  const [submit] = useSecurePost(`${baseUrl}/users`, {
    onSuccess: () => {
      onClose();
    },
  });
  const { Form } = useForm(submit);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-wrapper" overlayClassName="modal-overlay">
      <Form>
        <UserForm onClose={onClose} mode="new" />
      </Form>
    </Modal>
  );
};

// export default secure(AdminPage);
export default CreateUser;
