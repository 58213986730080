import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { permission } from '../../libs/rbac';
import { usePermission } from "../../libs/hook";
import Search from '../Search';
import AdminDropDown from "./AdminDropDown";
import DevToolsDropDown from "./DevToolsDropDown";
import LoggedUser from "./LoggedUser";


const Navbar = () => {
  // const [atTop, setAtTop] = useGlobalProp("atTop", true);
  const canAdmin = usePermission(permission.ADMIN);
  const canDevTools = usePermission(permission.DEV);
  const canDocs = usePermission(permission.DOC);
  const canApis = usePermission(permission.API);
  const canSearch = canDocs || canApis;

  // const handleScroll = useCallback((e) => {
  //   setAtTop(window.pageYOffset > 10 ? false : true);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  return (
    <nav
      className="w-full page-width text-gray-700 bg-white h-16 fixed top-0 animated z-40 shadow-lg"
    >
      <div className="md:flex items-center justify-between py-2 pl-8 md:pl-12">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold text-gray-800 md:text-3xl">
            <NavLink to="/"><img src="/ethias_logo.svg" width="80px" alt="Ethias Logo"></img></NavLink>
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path
                  className="hidden"
                  d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                />
                <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              </svg>
            </button>
          </div>
          <div className="flex flex-col md:flex-row hidden md:block ml-20">
            <Link label="Home" to="/" exact/>
            {canApis && <Link label="API" to="/apis" />}
            {canDevTools && <DevToolsDropDown />}
          </div> 
        </div>
        <div className="flex-grow mx-5">
            {canSearch && <Search />}
        </div>
        <div className="flex justify-between">
          
          <div className="flex flex-col md:flex-row hidden md:block self-center">
            <LoggedUser />
          </div>
          {canAdmin && <AdminDropDown />}
        </div>
      </div>
    </nav>
  );
};

type LinkProps = {
  label: string,
  to: string,
  exact?: boolean
}

const Link: FC<LinkProps> = ({ label, to, exact }) => {
  return (
    <NavLink
      to={to}
      exact={exact}
      className="navlink font-bold rounded  py-2 px-2 md:mx-2"
      activeClassName="selected font-medium"
    >
      {label}
    </NavLink>
  );
};

export default Navbar;
