import { App } from 'onekijs';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './modules/core/styles/tailwind.css';
import NotificationCenter from './modules/core/components/notification/NotificationCenter';
import RootRouter from './pages/_router';
import * as serviceWorker from './serviceWorker';
import settings from './settings';

ReactDOM.render(
  <App settings={settings}>
    <RootRouter />
    <NotificationCenter />
  </App>,
  document.getElementById('root'),
);

serviceWorker.unregister();
