import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EncryptorPage from './encryptor';
import ReleaseBranchPage from './release-branch';
import ReleaseBranchCustomPage from './release-branch-custom';
import NotificationPage from './notification';
import ProjectGeneratorPage from './project-generator';

const DevToolsRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/encryptor`}>
        <EncryptorPage />
      </Route>
      <Route path={`${match.path}/project-generator`}>
        <ProjectGeneratorPage />
      </Route>
      <Route path={`${match.path}/release-branches`}>
        <ReleaseBranchPage />
      </Route>
      <Route path={`${match.path}/release-branches-custom`}>
        <ReleaseBranchCustomPage />
      </Route>
      <Route path={`${match.path}/notifications`}>
        <NotificationPage />
      </Route>
    </Switch>
  );
};

export default DevToolsRouter;
