import { useLocation } from 'onekijs';
import React, { useMemo } from 'react';
import DropDown from './dropDown';
import { DropDownEntry } from './dropDown/DropDown';


const DevToolsDropDown = () => {
  const location = useLocation();
  // const [securityContext] = useSecurityContext();
  const entries = useMemo(() => {
    const entries: DropDownEntry[] = [{
      label: 'Password Encryptor',
      link: '/devtools/encryptor',
    }];
    // if (isBetaTester(securityContext)) {
      entries.push({
        label: 'Project Generator',
        link: '/devtools/project-generator',
      },{
        label: 'Release branch creator',
        link: '/devtools/release-branches',
      })
    // }
    return entries;
  }, []);

  const isActive = useMemo(() => {
    return location.pathname.startsWith('/devtools');
  }, [location.pathname]);

  return <DropDown active={isActive} label="DevTools" entries={entries} menuClassName="md:w-64" />
};

export default DevToolsDropDown;
