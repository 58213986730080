import { secure, useForm, useSecurePost, useSetting} from 'onekijs';
import React, { FC, useState } from 'react';
import DefaultError from '../../../modules/core/components/DefaultError';
import FormInputEntry from '../../../modules/core/components/form/FormInputEntry';
import FormSubmitEntry from '../../../modules/core/components/form/FormSubmitEntry';
import PageLayout from '../../../modules/core/layouts/PageLayout';
import { canReadWriteDevTools } from '../../../modules/core/libs/rbac';
import SubmitLabel from '../../../modules/core/components/form/SubmitLabel';
import EncryptedPassword from '../../../modules/devtools/components/encryptor/EncyptedPassword';


const DecryptorPage: FC = () => {
  const baseUrl = useSetting('server.baseUrl');

  const [decryptedPassword, setDecryptedPassword] = useState<string | undefined>(undefined);
  const [copiedDecrypt, setCopiedDecrypt] = useState(false);
  const [submitDecrypt, submitLoadingDecrypt] = useSecurePost(`${baseUrl}/devtools/decrypttexts`, {
    onSuccess: (datadecrypt) => {
      setDecryptedPassword(datadecrypt.decrypttext);
      setCopiedDecrypt(false);
    },
  });

  const { Form: DecryptForm } = useForm(submitDecrypt);

  return (
    <div >

      <PageLayout title="Password" titleColored="Decryptor">
        <>
          <p>
          Any sensitive information stored in the configuration files (application.yml) is encrypted. 
          This tool decrypts a string of characters using the private key of the Kubernetes platform
          </p>

          <DecryptForm name="Decrypt" className="w-full pt-10">
            <FormInputEntry
              label="Encrypted value"
              name="ciphertext"
              placeholder="Encrypted value"
              required={true}
              requiredMessage="Please indicate a encrypted value"
            />
            <FormInputEntry
              label="Private key"
              name="privatekey"
              placeholder="Deciphering key"
              required={true}
              requiredMessage="Please indicate the deciphering key "
            />
            <FormSubmitEntry formName="formDecrypt" label={()=><SubmitLabel label="Decrypt"/>} />
          </DecryptForm>
          {decryptedPassword && !submitLoadingDecrypt && (
            <EncryptedPassword copied={copiedDecrypt} setCopied={setCopiedDecrypt} encryptedPassword={decryptedPassword} text="Decrypted Value" />
          )}
        </>
      </PageLayout>
    </div>
    
  );
};

export default secure(DecryptorPage, canReadWriteDevTools, { ErrorComponent: DefaultError});
