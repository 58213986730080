import { useField } from 'onekijs';
import React, { FC } from 'react';

export type FormCardOptions = {
  name: string;
  value: string;
  Icon:  FC;
  title: string;
  Description: FC<{selected: boolean}>;
};

const FormCard: FC<FormCardOptions> = ( { name: fieldName, value: fieldValue, Icon, title, Description }) => {
  const { onChange, value } = useField(fieldName);
  const selected = value === fieldValue;
  const cls = {
    cusor: selected ? '': 'cursor-pointer',
    shadow: selected ? '' : 'shadow-lg',
    hoverColor: selected ? '' : 'hover:bg-gray-100',
    borderColor: selected ? 'border-indigo-500': 'border-gray-300',
    hoverBorderColor: selected ? '' : 'hover:border-gray-700',
    backgroundColor: selected ? 'bg-indigo-100' : 'bg-white'
  }
  
  return (
    <div className={`max-w-sm select-none ${cls.backgroundColor} border-2 ${cls.borderColor} p-6 rounded-md tracking-wide ${cls.shadow} mr-6 ${cls.hoverColor} ${cls.hoverBorderColor} ${cls.cusor}`} onClick={() => onChange(fieldValue)}>
      <div id="header" className="flex items-center text-center mx-auto mb-4">
        <span className="text-4xl">
          <Icon />
        </span>
        {/* <img alt="avatar" className="w-20 rounded-full border-2 border-gray-300" src="https://picsum.photos/seed/picsum/200" /> */}
        <div id="header-text" className="leading-5 ml-6 sm  text-2xl">
          <h5 id="job" className="font-semibold text-blue-600">
            {title}
          </h5>
        </div>
      </div>
      <div id="quote">
        <div className="italic text-gray-600">
          <Description selected={selected} />
        </div>
      </div>
    </div>
  );
};

export default FormCard;
