import React, { FC, useRef, useState } from 'react';
import { useClickAway } from '../../../libs/hook';
import DropDownEntry from './DropDownEntry';

export type DropDownEntry = {
  label: string;
  link: string;
  newTab?: boolean;
};

export type DropDownOptions = {
  active: boolean;
  label: string;
  className?: string;
  style?: React.CSSProperties;
  menuClassName?: string;
  entries: DropDownEntry[];
};

const DropDown: FC<DropDownOptions> = ({ active, label, entries, className, menuClassName, style }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setOpen(false);
  });

  return (
    <div className="relative inline-block" ref={wrapperRef}>
      <button
        onClick={() => setOpen(!open)}
        className={`navlink font-bold rounded py-2 px-2 font-medium${active ? ' selected' : ''}${className ? ` ${className}`: ''}`}
        style={style}
      >
        <span>{label}</span>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          className="rotate-0 inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {open && (
        <div className={`absolute  w-full mt-2 origin-top-right rounded-md shadow-lg${menuClassName ? ` ${menuClassName}`: ''}`}>
          <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
            {entries &&
              entries.map((entry) => <DropDownEntry label={entry.label} key={entry.link} link={entry.link} newTab={entry.newTab} onClick={setOpen} />)}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
