import { useValue } from 'onekijs';
import React, { FC } from 'react';
import FormInputEntry from '../../../../core/components/form/FormInputEntry';
import FormLabel from '../../../../core/components/form/FormLabel';
import FormSection from '../../../../core/components/form/FormSection';
import TableHeader from '../../../../core/components/TableHeader';
import { ethiasEnvs } from '../../../../core/libs/constants';

const ExternalApi: FC = () => {
  const apiType = useValue('apiTypeVs');
  const category = useValue('category');
  if (category !== 'vs' || apiType !== 'ext') return null;
  return (
    <FormSection
      title="External API"
      description="Define the configuration variables specific to an external API"
    >
      <FormLabel htmlFor="">External API hostname</FormLabel>
      <table className="border-collapse table-auto w-full bg-white table-striped relative">
        <thead>
          <tr className="text-left">
            {ethiasEnvs.map((env) => (
              <TableHeader title={env.toUpperCase()} key={env} bgColor="bg-white" />
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key="hostname">
            {ethiasEnvs.map((env) => (
              <td className="border-dashed border-t border-gray-200">
                <div className="mt-2 mx-4">
                  <FormInputEntry
                    name={`k8svs_api_domain_name_${env}`}
                    required={true}
                    requiredMessage="Please indicate a hostname"
                    defaultValue={`${env}.my-partner.com`}
                  />
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      </FormSection>
  );
};

export default ExternalApi;
