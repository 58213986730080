import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full border-none px-4 bg-gray-300 mt-5">
      <section className="flex flex-col md:flex-row md:justify-between md:border-solid md:border-t text-gray-900 font-light text-sm pt-4 pb-6 md:pt-5 md:pb-6 w-full">
        <div>
          <p className="leading-8 tracking-wide">
            © Ethias SA, rue des Croisiers 24, 4020 Liège
          </p>
        </div>
        <div>
          <p className="leading-8 tracking-wide ml-4">Privacy Policy</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;