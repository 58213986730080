export const camelCase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.substring(1);
};

export const pascalCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};

export const kebabCase= (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

export const validateProjectName = (input: string) => {
  const regexp = /^[a-z0-9-]+$/;
  if (input.search(regexp) === -1) {
    return "project/service name should only contains lowercase and dash characters";
  }

  return true;
};

export const camelCaseToDash = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .replace(/([A-Z]+)([A-Z][a-z])/g, "$1-$2")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/([0-9])([^0-9])/g, "$1-$2")
    .replace(/([^0-9])([0-9])/g, "$1-$2")
    .replace(/-+/g, "-")
    .toLowerCase();
};

export const flatten = (arr: Array<any>, obj: any) => {
  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];
    obj[key] = true;
  }
};

export const noEmpty = (str?: string) => {
  return str && str.length > 0;
}