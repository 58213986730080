import { useLocation } from 'onekijs';
import React, { useMemo } from 'react';
import DropDown from './dropDown';
import { DropDownEntry } from './dropDown/DropDown';

const entries: DropDownEntry[] = [
  {
    label: 'User management',
    link: '/admin/users',
  }, {
    label: 'Password decryptor',
    link: '/admin/devtools/decryptor',
  }, 
];

const AdminDropDown = () => {
  const location = useLocation();

  const isActive = useMemo(() => {
    return location.pathname.startsWith('/admin');
  }, [location.pathname]);

  return <DropDown active={isActive} label="Admin" entries={entries} className="md:ml-10" menuClassName="right-0 md:w-48"/>;
};

export default AdminDropDown;
