import React, { FC } from 'react';
import { GrDocumentTest } from 'react-icons/gr';
import { MenuContext, TestDetails } from '../typings/api';
import TestReportSubMenu from './TestReportSubMenu';

export type TestReportMenuEntryProps = {
  context: MenuContext;
  report: string;
  isOpen: boolean;
};

const TestReportMenuEntry: FC<TestReportMenuEntryProps> = ({ context, report, isOpen }) => {
  return (
    <li>
      <p
        className={`flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium focus:bg-gray-200 focus:shadow-outline${
          isOpen ? '' : ' cursor-pointer'
        }`}
        onClick={() => !isOpen && context.onTestSelect(report, context.type)}
      >
        <span className="text-gray-600">
          <GrDocumentTest className="h-5" />
        </span>
        <span>{report}</span>
      </p>
      {isOpen && (
        <TestReportMenuContent
          context={context}
        />
      )}
    </li>
  );
};

type testReportMenuContentProps = {
  context: MenuContext;
};

const buildTestEntry = (context: MenuContext, testDetails: TestDetails, name: string, type: string) => {
  if (!testDetails.hasTest) {
    return null;
  }

  if (testDetails.tests != null && testDetails.tests.length > 0) {
    return <TestReportSubMenu
      context={context}
      testReports={testDetails.tests}
      name={name}
      type={type}
    />
  }

  return <TestReportSubMenuEntry
    context={context}
    type={type}
    selected={context.type === type}
  />
}

const TestReportMenuContent: FC<testReportMenuContentProps> = ({ context }) => {
  
  return (
    <>
      {(!context.hasTests || context.loading) && <div>loading ...</div>}
      {context.hasTests && ! context.loading && (
        <div>
          {buildTestEntry(context, context.hasTests.unit, 'unit', 'unit')}
          {buildTestEntry(context, context.hasTests.integration, 'integration', 'integration')}
          {buildTestEntry(context, context.hasTests.jacoco, 'jacoco', 'jacoco')}
          {buildTestEntry(context, context.hasTests.e2e, 'End-to-end', 'e2e')}
        </div>
      )}
    </>
  );
};

type TestReportSubMenuEntryProps = {
  context: MenuContext;
  type: string;
  selected: boolean;
  selectedTest?: string;
};

const displayType = (type: string, selectedTest?: string) => {
  if (selectedTest != null) {
    return selectedTest;
  }

  if (type === 'unit') return 'Unit tests';
  if (type === 'integration') return 'Integration tests';
  if (type === 'jacoco') return 'Tests coverage';
  if (type === 'e2e') return 'End-to-end report';
  return '';
};

const TestReportSubMenuEntry: FC<TestReportSubMenuEntryProps> = ({ context, type, selected, selectedTest }) => {
  return (
    <p
      className={`ml-3 mb-1 cursor-pointer space-x-3 text-gray-600 px-2 rounded-md ${
        selected ? 'bg-gray-300' : ''
      } focus:bg-gray-200 focus:shadow-outline`}
      onClick={() => context.onTestSelect(context.report, type, selectedTest)}
    >
      {displayType(type, selectedTest)}
    </p>
  );
};

export {TestReportMenuEntry, TestReportSubMenuEntry};
