import React, { FC } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

export type ModalContentProps = {
  title: string,
  TitleIcon: FC<{className: string}>,
  onClose: React.MouseEventHandler,
  className?: string
}

const ModalContent: FC<ModalContentProps> = ({ title, TitleIcon, children, onClose, className }) => {
  className = className || 'md:w-1/2 sm:w-full rounded-lg shadow-lg bg-white my-3';
  return (
    <div className={className}>
      <div className="flex justify-between border-b border-gray-100 px-5 py-4">
        <div>
          <TitleIcon className="fas fa-exclamation-circle text-blue-500 inline-block" />
          <span className="font-bold text-gray-700 text-lg align-middle ml-2">
            {title}
          </span>
        </div>
        <div>
          <button onClick={onClose}>
            <FaTimesCircle className="text-red-500 hover:text-red-600 transition duration-150 inline-block" />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ModalContent;
