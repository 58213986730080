import { useLoginService } from 'onekijs';
import React from 'react';

const LoginPage = React.memo(() => {
  const [error] = useLoginService('cognito');
  if(error && error.code === 400 && error.payload && error.payload.error === 'invalid_grant') {
    window.localStorage.removeItem('onekijs.refresh_token');
    window.location.reload();
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>
  }
  return null;
});

LoginPage.displayName = 'LoginPage';

export default LoginPage;
