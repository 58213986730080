import React, { FC } from 'react';
import { MenuContext } from '../typings/api';
import { TestReportMenuEntry } from './TestReportMenuEntry';

export type TestReportMenuProps = {
  context: MenuContext;
};

const TestReportMenu: FC<TestReportMenuProps> = ({ context }) => {
  return (
    <>
      <h4 className="font-semibold text-lg text-gray-700 capitalize font-poppins tracking-wide">Test reports</h4>
      <span className="text-sm tracking-wide flex items-center space-x-1 -my-1 text-gray-500">{context.apiName}</span>
      <ul className="space-y-2 text-sm mt-6">
        {context.testReports &&
          context.testReports.map((testReport) => (
            <TestReportMenuEntry
              key={testReport}
              report={testReport}
              context={context}
              isOpen={context.report === testReport}
            />
          ))}
      </ul>
    </>
  );
};

export default TestReportMenu;
