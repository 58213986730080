import { SubmitButton, useSubmit, ValidationCode } from 'onekijs';
import React, { FC, FunctionComponent } from 'react';
import { FaSpinner } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { FiAlertTriangle } from 'react-icons/fi';

export type FormSubmitEntryOptions = {
  label?: FunctionComponent | string;
  loading?: boolean;
  width?: number;
  height?: number;
  formName?: string;
};
const FormSubmitEntry: FC<FormSubmitEntryOptions> = ({ label, width, height,formName="submitButtonTooltip" }) => {
  const { submitting: loading, code, fields } = useSubmit();
  const disabled = code <= ValidationCode.Error;
  let Label: FC;
  if (label && typeof label === 'string') Label = () => <>{label}</>;
  else Label = label as FunctionComponent;
  return (
    <div className="flex items-center">
      <div className="w-full">
        <SubmitButton
          className={`float-right shadow ${
            disabled ? 'bg-gray-500 hover:bg-gray-900 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-900'
          } focus:shadow-outline focus:outline-none text-white text-center font-bold py-2 px-4 rounded`}
          type="submit"
          style={{ width: `${width ? width : 120}px`, height: `${height ? width : 40}px` }}
          showErrors={false}
          data-tip
          data-for={formName}
        >
          {!loading && (
            <span className="m-auto">
              <Label />
            </span>
          )}
          {loading && <FaSpinner className="fa-spin m-auto" />}
        </SubmitButton>
        {disabled && fields && (
          <ReactTooltip id={formName} type="error" place="top" effect="solid">
            <p className="mb-2 uppercase">
              <FiAlertTriangle className="inline-block mr-2" />
              <span>Some fields are in error</span>
              <FiAlertTriangle className="inline-block ml-2" />
            </p>
            <ul>
              {Object.keys(fields).map((fieldName) => (
                <li>
                  <span className="font-bold mr-2">{fieldName}:</span>
                  {fields[fieldName]}
                </li>
              ))}
            </ul>
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default FormSubmitEntry;
