import React, { FC, useState } from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';

export type NextRelease = {
  major: string;
  minor: string;
  branches: string[];
};

type NextReleaseBranchOptions = {
  nextRelease?: NextRelease;
  values: {
    major?: string;
  };
};

const NextReleaseBranch: FC<NextReleaseBranchOptions> = ({ nextRelease, values }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-6" role="alert">
      <p className="cursor-pointer" onClick={() => setShowDetails(!showDetails)}>
        The new branch created will be:{' '}
        <span className="font-bold">
          {nextRelease && nextRelease.major
            ? values.major
              ? nextRelease.major
              : nextRelease.minor
            : '(please choose a GIT repository)'}
        </span>
        {nextRelease?.branches && nextRelease?.branches?.length > 0 && (
          <span className="font-semibold mb-5 cursor-pointer" onClick={() => setShowDetails(!showDetails)}>
            {!showDetails && <BiCaretUp className="inline-block" style={{ marginTop: '2px' }} />}
            {showDetails && <BiCaretDown className="inline-block" style={{ marginTop: '2px' }} />}
          </span>
        )}
      </p>
      {showDetails && nextRelease?.branches && (
 
        <div className="w-full bg-yellow-100 pb-5 pt-1">
           <h3 className="text-xl mt-5 px-2 leading-tight font-semibold text-yellow-700">Existing release branches</h3>
          {nextRelease.branches.map((branch) => (
            <div className="flex my-1 hover:bg-blue-lightest rounded" key={branch}>
              <div className="w-8 h-5 text-center py-1">
                <p className="text-3xl p-0 text-yellow-700">&bull;</p>
              </div>
              <div className="w-full h-5 py-4 px-1">
                <p className="font-medium text-yellow-700">{branch}</p>
              </div>
            </div>
          ))}
        </div>
        
      )}
    </div>
  );
};

export default NextReleaseBranch;
