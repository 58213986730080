import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LoginPage from '.';
import LoginCallbackPage from './callback';
import LogoutPage from './logout';
import LogoutCallbackPage from './logout/callback';
import SignupPage from './signup';

const LoginRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/callback`}>
        <LoginCallbackPage />
      </Route>
      <Route path={`${match.path}/signup`}>
        <SignupPage />
      </Route>      
      <Route path={`${match.path}/logout/callback`}>
        <LogoutCallbackPage />
      </Route>
      <Route path={`${match.path}/logout`}>
        <LogoutPage />
      </Route>
      <Route path={match.path}>
        <LoginPage />
      </Route>
    </Switch>
  );
};

export default LoginRouter;
