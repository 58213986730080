import { useLogoutService } from 'onekijs';
import React from 'react';

const LogoutPage = React.memo(() => {
  useLogoutService();
  return null;
});

LogoutPage.displayName = 'LogoutPage';

export default LogoutPage;
