import { useLoginCallbackService } from 'onekijs';
import React from 'react';
//import { useLoginCallbackService } from '../../lib/login';

const LoginCallbackPage = React.memo(() => {
  const [error] = useLoginCallbackService('cognito');
  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }
  return null;
});

LoginCallbackPage.displayName = 'CognitoCallbackPage';

export default LoginCallbackPage;
