import React, { FC } from 'react';
import ExternalApi from './block/ExternalApi';
import GeneralInfos from './block/GeneralInfos';
import JavaBatch from './block/JavaBatch';
import JavaMicroservice from './block/JavaMicroservice';
import Kubernetes from './block/Kubernetes';
import ProjectType from './block/ProjectType';

const ProjectGenerator: FC = React.memo(() => {
  // const category = useValue('category');
  // const apiType = useValue('apiType');
  // const springDependencies = useValue('springDependencies');

  return (
    <>
      <ProjectType />
      <GeneralInfos />
      <JavaMicroservice />
      <JavaBatch />
      <Kubernetes />
      <ExternalApi />
    </>
  );
});

export default ProjectGenerator;

/*{category === 'vs' && (
        <>
          <FormSelectEntry name="apiType" label="API Type" required={true}>
            <option value=""></option>
            <option value="vs">OI (AWS API GTW)</option>
            <option value="ext">External API (K8S)</option>
            <option value="caf">CAF API (SAG API GTW and K8S)</option>
            <option value="esb">ESB API (SAG API GTW and K8S)</option>
            <option value="guidewire">Guidewire API (SAG API GTW and K8S)</option>
            <option value="zos">ZOS API (SAG API GTW and K8S)</option>
          </FormSelectEntry>
          {apiType === 'ext' && <ExternalApi />}
          {apiType === 'vs' && <AwsVirtualService />}
          {isSystemApi(apiType) && <SystemApi />}
        </>
      )}
      {category === 'appJava' && (
        <>
          <FormSelectEntry name="apiType" label="API Type" required={true}>
            <option value=""></option>
            <option value="chn">OI Channel API</option>
            <option value="exp">OI Experience API</option>
            <option value="cap">OI Capability API</option>
          </FormSelectEntry>
          {isOiApplication(apiType) && <JavaApp />}
        </>
      )}*/
