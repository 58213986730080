import { useSecurityContext } from 'onekijs';
import { Link } from 'react-router-dom';
import React from 'react';

const LoggedUser = () => {
  const [email, emailLoading] = useSecurityContext('email');

  if (emailLoading) return null;

  return (
    <>
      {email && (
        <>
          <span className="mr-2 text-green-600">{email}</span>|
          <Link to="/login/logout" className="ml-2 font-medium text-red-900">Logout</Link>
        </>
      )}
      {!email && (
        <>
          <Link to="/login" className="font-medium text-green-600">Login</Link>
        </>
      )}
    </>
  );
};

export default LoggedUser;
