import { useLogoutCallbackService } from 'onekijs';
import React from 'react';

const LogoutCallbackPage = React.memo(() => {
  useLogoutCallbackService();
  return null;
});

LogoutCallbackPage.displayName = 'LogoutCallbackPage';

export default LogoutCallbackPage;
