import { useValidation } from 'onekijs';
import React, { FC, FunctionComponent } from 'react';

export type FormEntryOptions = {
  name: string;
  Label?: FunctionComponent;
  labelAfter?: boolean;
  FormElement: FunctionComponent<any>;
  help?: string;
};
const FormEntry: FC<FormEntryOptions> = ({ name, Label, FormElement, labelAfter, help, ...formElementProps }) => {
  const validation = useValidation(name);
  return (
    <div className="flex flex-wrap -mx-3 mb-4">
      <div className="w-full px-3">
        {Label && !labelAfter && <Label />}
        <FormElement name={name} {...formElementProps}/>
        {Label && labelAfter && <Label />}
        {help && <p className="text-gray-600 text-xs italic -mt-3">{help}</p>}
        <p className="text-red-500 text-xs italic">{validation.message}</p>
      </div>
    </div>
  );
};

export default FormEntry;
