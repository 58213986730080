import { Item, ItemMeta } from 'onekijs';
import React, { FC, useContext } from 'react';
import { TableContext, TableContextProps } from './Table';

export type TBodyTrOptions<T, M> = {
  index: number;
  item: Item<T, M>;
};

export type TBodyOptions<T, M> = {
  Tr: FC<TBodyTrOptions<T, M>>;
};

const Tbody: FC<TBodyOptions<any, ItemMeta>> = ({ Tr }) => {
  const { collection } = useContext<TableContextProps>(TableContext);
  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {collection.items &&
        collection.items.map((item, i) => {
          if (item) {
            return <Tr index={i} item={item} />;
          }
          return null;
        })}
    </tbody>
  );
};

export default Tbody;
