import { Notification } from 'onekijs';
import * as React from 'react';
import { TypeOptions } from 'react-toastify';

export interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  type: TypeOptions;
  ariaLabel?: string;
}

export const CloseButton = (notification: Notification) => {
  return ({ closeToast, type, ariaLabel = 'close' }: CloseButtonProps) => {
    return (
      <button
        className={`Toastify__close-button Toastify__close-button--${type}`}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          notification.remove();
          closeToast(e);
        }}
        aria-label={ariaLabel}
      >
        <svg aria-hidden="true" viewBox="0 0 14 16">
          <path
            fillRule="evenodd"
            d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
          />
        </svg>
      </button>
    );
  };
};
