import React, { FC } from 'react';
import { FcOk } from "react-icons/fc";
import { MdCancel } from "react-icons/md";

export type ModalNotificationProps = {
  notification: {
    payload: {
      type: 'success' | 'error' | 'warning' | 'info',
      [x: string]: any
    }
  }
}

const ModalNotification: FC<ModalNotificationProps> = ({ notification }) => {
  const success = notification.payload.type === 'success';
  return (
    <p className={`text-${success ? 'green' : 'red'}-500 font-medium`}>
      {!success && <MdCancel className="inline-block mr-2" />}
      {success && <FcOk className="inline-block mr-2" />}
      {notification.payload.message}
    </p>
  );
};

export default ModalNotification;